import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "./AvatarModal.css";
import { AvatarImage } from "../AvatarImage";
import { Button } from "@mui/material";
import { useGame } from "../../../hooks/useGame";
import { useCallback } from "react";
import { cuni } from "../../../../../shared/services/cuni/cuni";
import { ModalProps } from "../../../../../shared/components/modal/ModalProps";
import { useTranslation } from "react-i18next";

type AvatarModalProps = ModalProps;

export const AvatarModal = (props: AvatarModalProps) => {
  const { open, handleClose } = props;
  const { t } = useTranslation();

  const { game } = useGame();

  const selectAvatar = useCallback(
    (path: string) => {
      if (game.me.key) cuni.update(game.me.key, "__avatarUrl", path);
      handleClose();
    },
    [game, handleClose],
  );

  const allUrls = game.root.overrideAvatars
    ? (game.root.settings?.avatars || []).map((x) => x.avatarUrl)
    : Array.from(Array(16).keys()).map((x) => {
        const urlPath = `0${x + 1 > 9 ? x + 1 : `0${x + 1}`}`;
        const imgUrl = `/images/avatars/image_part_${urlPath}.png`;
        return imgUrl;
      });

  const all = allUrls.map((imgUrl) => {
    return (
      <div
        key={imgUrl}
        className="avatarModal__item"
        onClick={() => selectAvatar(imgUrl)}
      >
        <AvatarImage imgUrl={imgUrl} />
      </div>
    );
  });

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className="modal">
        <Typography variant="h6" component="h2">
          {t("AVATAR.AVATAR")}
        </Typography>
        <Typography id="modal-avatar-description" sx={{ mt: 2 }}>
          {t("AVATAR.DESC")}
        </Typography>
        <Box className="avatarModal__container">{all}</Box>
        <Button onClick={handleClose}>{t("COMMON.CLOSE")}</Button>
      </Box>
    </Modal>
  );
};
