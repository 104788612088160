interface ExportProps {
  object: any;
  name: string;
}
export const file = {
  export: (props: ExportProps) => {
    const { object, name } = props;
    const content = JSON.stringify(object);
    const blob = new Blob([content], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${name}.json`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  },

  import: (event: React.ChangeEvent<HTMLInputElement>): Promise<any> => {
    return new Promise((resolve, reject) => {
      const file = event.target.files?.[0];
      if (file) {
        const fileSizeInKB = file.size / 1024;
        if (fileSizeInKB > 11) {
          console.error("File is too big");
          return reject();
        }
        const reader = new FileReader();
        reader.onload = function (progressEvent: ProgressEvent<FileReader>) {
          try {
            const result = progressEvent.target?.result;
            if (typeof result !== "string") return;
            const jsonObject = JSON.parse(result);
            console.log("Parsed JSON object:", jsonObject);
            resolve(jsonObject);
          } catch (error) {
            console.error("Error parsing JSON:", error);
            reject();
          }
        };
        reader.readAsText(file);
      }
    });
  },
};
