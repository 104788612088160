import { useCallback } from "react";
import { useAuth } from "../../features/auth/useAuth";
import { useGame } from "../../features/character/hooks/useGame";

export const useGamePermission = () => {
  const { data } = useAuth();
  const { game } = useGame();

  const hasGamePermission = useCallback(
    (permission: GamePermission) => {
      if (game.gameMasterId === data?.userId) return true;
      if (permission === "canEditCharacter") return game.root?.playerEditSelf;
      return false;
    },
    [data?.userId, game],
  );

  return { hasGamePermission };
};

export const GamePermissions = {
  editPanel: "Admin",
  sendSurvey: "Admin",
  chooseReceiver: "Admin",
  chooseName: "Admin",
  canChangeColor: "Admin",
  canEditGame: "Admin",
  canEditMap: "Admin",
  canEditCharacter: "Admin",
  writeStory: "Admin",
  canStartSession: "Admin",
};

export type GamePermission = keyof typeof GamePermissions;
