import React from "react";
import { CharacterTablePanel } from "../../../../../../model/Game";
import { Table } from "../../../../../../shared/components/ui/Table";
import { PanelMenu } from "../panel-menu/PanelMenu";

interface TablePanelViewProps {
  panel: CharacterTablePanel;
  path: string;
  className?: string;
}

export const TablePanelView = (props: TablePanelViewProps) => {
  const { panel, path,className } = props;
  const headers = panel.__columns || [];
  const rows = panel.__data || [];
  return (
    <PanelMenu actions={["ADD_ROW", "EXPORT", "REMOVE_PANEL"]} path={path}>
      <Table
        headers={headers}
        rows={rows}
        title={panel.title}
        titleSufix={path}
        className={className}
      />
    </PanelMenu>
  );
};
