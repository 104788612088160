export interface User {
  id: number;
  email: string;
  userName: string;
  licensePlan: 'Free';
  licenseExpiredDate: string;
}

export interface UserOption {
  key: number;
  name: string;
  data: User;
}

export const mapUserToOption = (user: User): UserOption => {
  return {
    data: user,
    key: user.id,
    name: user.userName,
  };
};
