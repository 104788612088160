import React, { useState } from "react";
import { BasicView } from "../shared/components/ui/BasicView";
import { Alert, Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { FormValidation } from "../shared/services/validator/FormValidation";
import { useAuth } from "../features/auth/useAuth";
import { RegisterDataInput } from "../features/auth/AuthContext";
import { Conditional } from "../shared/components/logic/Conditional";
import { LoadingButton } from "../shared/components/ui/LoadingButton";
import { useTranslation } from "react-i18next";

export interface RegisterPageProps {
  children?: React.ReactElement;
}

type RegisterPageForm = RegisterDataInput;

export const RegisterPage: React.FC<RegisterPageProps> = (
  props: RegisterPageProps,
) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<RegisterPageForm>();
  const [loading, setLoading] = useState(false);

  const [apiError, setApiError] = useState<string>();
  const submit = async (data: RegisterPageForm) => {
    try {
      setLoading(true);
      const { needCodeValidaton } = await auth.register(data);
      if (needCodeValidaton) navigate("/confirm");
      else navigate("/list");
    } catch (error) {
      // @ts-ignore
      console.error(error?.response?.data?.message);
      setApiError(t("ERROR.PASSWORD"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <BasicView>
        <label>{t("LOGIN.SIGN_UP")}</label>
        <TextField
          label={t("LOGIN.USERNAME")}
          {...register("username", { required: true })}
          error={Boolean(errors.username)}
        />
        <TextField
          label={t("LOGIN.EMAIL")}
          type="email"
          {...register("email", {
            pattern: FormValidation.email,
            required: true,
          })}
          error={Boolean(errors.email)}
        />
        <TextField
          label={t("LOGIN.PASWORD")}
          type="password"
          {...register("password", { minLength: 8, required: true })}
          error={Boolean(errors.password)}
          helperText={t("LOGIN.CHAR_LENGTH_REQUIREMENTS")}
        />

        <Conditional condition={apiError}>
          <Alert severity="error">{apiError}</Alert>
        </Conditional>
        <LoadingButton
          loading={loading}
          disabled={!isValid}
          variant="contained"
          type="submit"
        >
          {t("LOGIN.REGISTER")}
        </LoadingButton>
        <Button onClick={() => navigate("/login")}>
          {t("LOGIN.BACK_TO_LOGIN")}
        </Button>
      </BasicView>
    </form>
  );
};
