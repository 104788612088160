import "./ColorPicker.css";
import { Popover, IconButton, Box } from "@mui/material";
import { useRef, useState } from "react";
import ColorLensIcon from "@mui/icons-material/ColorLens";

interface ColorPickerProps {
  selected?: string;
  anchorEl?: HTMLDivElement;
  onColorChange: (color: string | undefined) => void;
  Icon?: React.ComponentType<React.HTMLAttributes<HTMLDivElement>>;
}

const colors = [
  "#4CAF50", // Green
  "#F44336", // Red
  "#2196F3", // Blue
  "#FFC107", // Amber
  "#9C27B0", // Purple
  "#FF9800", // Orange
  "#00BCD4", // Cyan
  "#8BC34A", // Light Green
  "#FFEB3B", // Yellow
  "#3F51B5", // Indigo
  "#E91E63", // Pink
  "#607D8B", // Blue Grey
  "white",
  "transparent",
];
export const ColorPicker = (props: ColorPickerProps) => {
  const { selected, anchorEl, onColorChange, Icon = ColorLensIcon } = props;
  const [open, setOpen] = useState(false);

  const onColorClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    color: string,
  ) => {
    e.stopPropagation();
    if (color === "transparent") onColorChange(undefined);
    else onColorChange(color);
    setOpen(false);
  };

  const localRef = useRef(null);
  return (
    <IconButton
      ref={localRef}
      onClick={() => {
        setOpen(true);
      }}
    >
      <Popover
        anchorEl={anchorEl ?? localRef.current}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Box className="paper shared__color-picker" padding="0.5rem">
          {colors.map((cc) => (
            <button
              key={cc}
              className="shared__color-button"
              style={{ background: cc }}
              onClick={(e) => onColorClick(e, cc)}
            >
              {cc === "transparent" ? (
                <div className="diagonal-line" />
              ) : undefined}
            </button>
          ))}
        </Box>
      </Popover>

      <Icon style={{ color: selected }} />
    </IconButton>
  );
};
