import { useCallback, useMemo } from "react";
import { GameCheckbox } from "./GameCheckbox";
import { Button, Typography } from "@mui/material";
import { Table } from "../../../shared/components/ui/Table";
import { QuickEditModalProvider } from "../../quick-edit/hooks/useQuickEdit";
import { SETTINGS_ADMIN_KEY } from "../../../model/Game";
import { useGame } from "../../character/hooks/useGame";
import { cuni } from "../../../shared/services/cuni/cuni";
import { uuid } from "../../../shared/utils/uuid";
import { useTranslation } from "react-i18next";
import UploadButton from "../../../shared/components/logic/UploadButton";

export const AvatarView = () => {
  const { game } = useGame();
  const { t } = useTranslation();

  const uploadAvatar = useCallback(
    (key: string, avatarUrl: string) => {
      const avatars = game.root.settings?.avatars || [];
      avatars.push({
        key,
        avatarUrl,
      });
      cuni.update(SETTINGS_ADMIN_KEY, "avatars", avatars);
    },
    [game],
  );

  const add = useCallback(() => uploadAvatar(uuid(), "AVATAR"), [uploadAvatar]);

  const { rows, headers } = useMemo(() => {
    const avatars = game.root.settings?.avatars || [];
    const headers = [
      {
        key: "avatarUrl",
        title: "Avatar url",
      },
    ];
    return { rows: avatars, headers };
  }, [game]);

  return (
    <QuickEditModalProvider
      character={{ ...game.root.settings, key: SETTINGS_ADMIN_KEY }}
    >
      <div className="column" style={{ paddingRight: "1rem" }}>
        <GameCheckbox
          label={t("AVATAR.OVERRIDE_AVATARS")}
          parameter="overrideAvatars"
        />
        <Typography>{t("AVATAR.OVERRIDE_INFO")}</Typography>
        <UploadButton label={`${t('COMMON.UPLOAD')} ${t('AVATAR.AVATAR')}`} onUpload={uploadAvatar} />
        <Button variant="outlined" onClick={add}>
          {t("AVATAR.ADD")}
        </Button>
        <Table
          titleSufix="avatars"
          directList
          root={{ ...game.root.settings, key: SETTINGS_ADMIN_KEY }}
          headers={headers}
          rows={rows}
          className="paper-outline"
        />
      </div>
    </QuickEditModalProvider>
  );
};
