import { Typography, Button, Link, LinearProgress } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Character, GameDice, SETTINGS_ADMIN_KEY } from "../../model/Game";
import { cuni } from "../../shared/services/cuni/cuni";
import { uuid } from "../../shared/utils/uuid";
import { useGame } from "../character/hooks/useGame";
import { QuickEditModalProvider } from "../quick-edit/hooks/useQuickEdit";
import { Table } from "../../shared/components/ui/Table";
import { SwitchView } from "../../shared/components/ui/switch-view/SwitchView";
import { Resource } from "../../model/Resource";
import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../../shared/services/apiClient";
import { useUser } from "../auth/useUser";
import { config } from "../../config";
import { useResources } from "../../shared/hooks/use-resources";
import { useAws } from "../../shared/hooks/use-aws";

export const UIDatabaseView = () => {
  const { game } = useGame();
  const { t } = useTranslation();

  const [view, setView] = useState<string | null>(null);

  const [value, setValue] = useState<GameDice>();
  const [tokenIndex, setTokenIndex] = useState<number>();

  const { data } = useResources();

  const { rows, headers } = useMemo(() => {
    const items = data || [];
    const headers = [
      {
        key: "key",
        title: t("COMMON.NAME"),
      },
      {
        key: "size",
        title: t("COMMON.SIZE"),
      },
      {
        key: "action",
        title: t("COMMON.ACTION"),
        cell: (value: Resource, index: number) => (
          <Link color="primary" href={value.url}>
            {t("COMMON.DOWNLOAD")}
          </Link>
        ),
      },
    ];
    const rows = items.map((x) => ({
      ...x,
      action: "",
    }));
    return { rows, headers };
  }, [data, t]);

  const currentValue = (data ?? []).reduce((p, c) => p + c.size, 0);
  const { remove } = useAws();
  return (
    <QuickEditModalProvider character={{} as Character}>
      <SwitchView
        setView={setView}
        view={view}
        viewComponents={{
          detail: <div></div>,
        }}
      >
        <div className="column" style={{ paddingRight: "1rem" }}>
          <Typography>
            {t("DATABASE.OVERRIDE_INFO")} -{" "}
            {t("COMMON.MAX_OF", {
              current: (currentValue / (1024 * 1024 * 1)).toFixed(2),
            })}
          </Typography>
          <LinearProgress
            title={t("COMMON.MAX_OF", {
              current: currentValue / (1024 * 1024 * 1),
            })}
            variant="determinate"
            value={(currentValue / (1024 * 1024 * 10)) * 100}
          />
          <Table
            directList
            headers={headers}
            className="paper-outline"
            rows={rows}
            disableGenericMenu
            onRowRemove={(resource: Resource) => {
              if (resource?.id) remove(resource?.id);
            }}
          />
        </div>
      </SwitchView>
    </QuickEditModalProvider>
  );
};
