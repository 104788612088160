import "./Avatar.css";
import placeholder from "../../../../assets/images/egg.png";

export const AvatarImage = ({
  imgUrl,
  online,
}: {
  imgUrl: string;
  online?: boolean;
}) => {
  return (
    <img
      alt="avatar"
      className="avatar__image"
      src={imgUrl || placeholder}
      style={{ borderColor: online ? "green" : "red" }}
    />
  );
};
