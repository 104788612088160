import { Game, mapIdToPath } from "../../../model/Game";
import {
  WebHookMessage,
  WebHookSubType,
  WebHookType,
} from "../../../model/WebHookMessage";
import { webHookStore } from "../../../store/WebHookStore";
import { gameStorage } from "../storage";
import { rollDice } from "./utils";

// TODO: forbid update for ADMIN only
const update = (charId: number, path: string, value: string | number | any) => {
  console.debug(charId, path, value);
  const gamePartPath = mapIdToPath(charId);
  if (gamePartPath) {
    const game = gameStorage.get<Game>();
    if (!game)
      throw new Error("You should be in game context when send message.");
    if (!game.root[gamePartPath]) {
      updateGame(gamePartPath, {
        [path]: value,
      });
      // @ts-ignore
    } else updateGame(`${gamePartPath}.${path}`, value);
    return;
  }
  const game = gameStorage.get<Game>();
  if (!game)
    throw new Error("You should be in game context when send message.");
  const gameId = game.id;

  webHookStore.push({
    type: WebHookType.GameUpdate,
    subType: "GAME_UPDATE",
    metaData: {
      value,
      charId,
      gameId,
      path,
    },
  });
};

const updateGame = (path: keyof Game["root"], value: string | number | any) => {
  const game = gameStorage.get<Game>();
  if (!game)
    throw new Error("You should be in game context when send message.");
  const gameId = game.id;

  webHookStore.push({
    type: WebHookType.GameUpdate,
    subType: "GLOBAL_GAME_UPDATE",
    metaData: {
      value,
      gameId,
      path,
    },
  });
};

/* background: rgba(100,100, 0, 0.8); */
const log = (
  message: string,
  color: string = "gray",
  user: string = "SYSTEM",
  subType: WebHookSubType,
  receiverId?: number,
  receiver?: string,
) => {
  const game = gameStorage.get<Game>();
  if (!game)
    throw new Error("You should be in game context when send message.");
  const gameId = game.id;

  webHookStore.push({
    type: WebHookType.UserText,
    user,
    message,
    color,
    subType,
    receiverId,
    receiver,
    metaData: {
      gameId,
    },
  });
};

const rollProps = () => ({
  color: "aquamarine",
  subType: "ACTION_ROLL" as WebHookSubType,
});

const likeRoll = (diceName: string, result: string) => {
  pushMessage((game) => {
    return {
      type: WebHookType.UserText,
      ...rollProps(),
      user: `${game.me.name}`,
      message: `${diceName} => ${result}.`,
    };
  });
};

const roll = (formula: string) => {
  pushMessage((game) => {
    const expressionResult = rollDice(formula);
    return {
      type: WebHookType.UserText,
      ...rollProps(),
      user: "ROLL",
      message: `${formula} => ${expressionResult}.`,
    };
  });
};

const userRoll = (formula: string, name?: string) => {
  pushMessage((game) => {
    const expressionResult = rollDice(formula);
    return {
      type: WebHookType.UserText,
      ...rollProps(),
      user: `${name || game.me.name}`,
      message: `${formula} => ${expressionResult}`,
    };
  });
};

const refreshMessage = (messageId: number) => {
  pushMessage(() => {
    return {
      type: WebHookType.UserTextRefresh,
      subType: "CHAT_REFRESH",
      contextObjectId: messageId,
    };
  });
};

type ComposeMessage = (game: Game) => WebHookMessage;
const pushMessage = (compose: ComposeMessage) => {
  try {
    const game = gameStorage.get<Game>();
    if (!game)
      throw new Error("You should be in game context when send message.");

    const hook = compose(game);
    if (hook.metaData) {
      hook.metaData.gameId = game.id;
    } else {
      hook.metaData = { gameId: game.id };
    }

    webHookStore.push(hook);
  } catch {
    return "nope";
  }
};

const getAndUpdateMessage = (messageId: number) =>
  webHookStore.getAndUpdateMessage(messageId);

// const ask = {};
// const addRule = {};
// const addAction = {};
// const reaction = {}; // User executes "action" on some "rule"

export const cuni = {
  update,
  updateGame,
  log,
  likeRoll,
  roll,
  userRoll,
  pushMessage,
  refreshMessage,
  getAndUpdateMessage,
};
declare var window: any;
window.cuni = cuni;
