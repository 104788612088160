import { useMemo } from "react";
import {
  Character,
  CharacterTabPanel,
  CharacterTabPanelBase,
} from "../../../model/Game";
import { getGameDbList, useGameDb } from "./useGameDb";
import { cuni } from "../../../shared/services/cuni/cuni";
import { rootStore } from "../../../store/RootStore";

export const useGameDbPanels = (database: Character | undefined) => {
  return useMemo(() => {
    if (!database) return [];
    return mapGameDbAsPanel(database);
  }, [database]);
};

export const useGamePanels = () => {
  const { list: dbList } = useGameDb();
  return useMemo<CharacterTabPanel[]>(() => {
    return dbList.map(mapGameDbAsPanel).flatMap((x) => x);
  }, [dbList]);
};

export const updatePanel = <T extends CharacterTabPanelBase>(
  key: string,
  updater: (state: T) => T,
) => {
  const { list: dbList } = getGameDbList(rootStore.game!);

  const panelDbTuple = dbList
    .map((db) => mapGameDbAsPanel(db).map((p) => ({ panel: p, db })))
    .flatMap((x) => x)
    .find((x) => x.panel.key === key);
  if (!panelDbTuple) {
    console.error("This panel cannot be update - no key.");
    return;
  }
  const { panel, db } = panelDbTuple;
  cuni.update(db.key, panel.__path!, updater(panel as any));
};

export const mapGameDbAsPanel = (database: Character) => {
  const panels: CharacterTabPanel[] = [
    { ...database, __path: "" } as unknown as CharacterTabPanel,
    ...(database.__panels?.map((x, idx) => ({
      ...x,
      key: x.key ? x.key : `__panels.${idx}`,
      __path: `__panels.${idx}`,
    })) || []),
    ...(database.__tabs
      ?.map((x, idx) => ({
        ...x,
        key: x.key ? x.key : `__tabs.${idx}`,
        __path: `__tabs.${idx}`,
      }))
      .flatMap(
        (x) =>
          x.__panels?.map((y, idy) => ({
            ...y,
            key: y.key ? y.key : `${x.key}.__panels.${idy}`,
            __path: `${x.__path}.__panels.${idy}`,
          })) || [],
      ) ?? []),
  ];
  return panels;
};
