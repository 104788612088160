import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
} from "@microsoft/signalr";
import { makeObservable, observable, runInAction } from "mobx";
import { config } from "../config";
import {
  UserTextMessage,
  WebHookMessage,
  WebHookType,
} from "../model/WebHookMessage";
import { rootStore } from "./RootStore";
import { authStorage } from "../shared/services/storage";
import { AuthData } from "../features/auth/AuthContext";
import { apiClient } from "../shared/services/apiClient";
import { tokenProviderRef } from "../shared/providers/toast/ToastProvider";

// TODO: it should be date, but somehow not working
export const orderByCreated = (a: WebHookMessage, b: WebHookMessage): number =>
  a.id! > b.id! ? 1 : -1; // (new Date(a.created!) > new Date(b.created!) ? 1 : -1);

export class WebHookStore {
  hooks: WebHookMessage[] = [];
  chat: UserTextMessage[] = [];
  connection: HubConnection | null = null;

  constructor() {
    makeObservable(this, { hooks: observable, chat: observable });
  }

  disconnect() {
    this.connection?.stop();
    this.connection = null;
  }

  connect(gameId: number) {
    if (this.connection) return;
    this.connection = new HubConnectionBuilder()
      .withUrl(`${config.baseUrl}/console?gameId=${gameId}`, {
        accessTokenFactory() {
          const auth = authStorage.get<AuthData>();
          if (!auth) throw new Error("No token");
          return auth?.token;
        },
      })
      .withAutomaticReconnect()
      .build();

    this.connection.start().then(this.handleSuccess).catch(this.handleError);
  }

  push(message: WebHookMessage) {
    if (
      this.connection &&
      this.connection.state === HubConnectionState.Connected
    ) {
      this.connection.send("SendMessage", message).catch(this.handleError);
    } else {
      console.warn("No connection to server yet.");
    }
  }

  // TODO: circluar dependency with rootStore... refactor
  setInitial(messages: UserTextMessage[]) {
    runInAction(() => {
      this.hooks = messages;
      this.chat = messages;
    });
  }
  pushMore(messages: UserTextMessage[]) {
    runInAction(() => {
      this.hooks = [...this.hooks, ...messages].sort(orderByCreated);
      this.chat = [...this.chat, ...messages].sort(orderByCreated);
    });
  }

  private handleSuccess = () => {
    if (!this.connection) return;
    this.connection.on("ReceiveMessage", (message: WebHookMessage) => {
      const hooks = [...this.hooks];
      hooks.push(message);

      runInAction(() => {
        this.hooks = hooks;
        if (message.type === WebHookType.GameDidUpdate) {
          rootStore.update(message.game);
        }
        if (
          message.type === WebHookType.UserTextRefresh &&
          message.contextObjectId
        )
          this.getAndUpdateMessage(message.contextObjectId);

        if (message.type === WebHookType.UserText) {
          if (message.subType === "ACTION_ROLL") {
            tokenProviderRef?.addToast(`${message.user}: ${message.message}`);
          }
          this.chat = hooks
            .filter((x) => x.type === WebHookType.UserText)
            .map<UserTextMessage>((x) => x as UserTextMessage);
        }

        rootStore.setOnline(message.metaData?.userOnlineIds ?? []);
      });
    });
  };

  public getAndUpdateMessage = async (messageId: number) => {
    const { data } = await apiClient.get<UserTextMessage>(
      `${config.baseUrl}/api/message/${messageId}`,
    );
    runInAction(() => {
      this.chat = this.hooks
        .map((x) => {
          if (x.id === messageId) {
            return data;
          }
          return x;
        })
        .filter((x) => x.type === WebHookType.UserText)
        .map<UserTextMessage>((x) => x as UserTextMessage);
    });
  };

  private handleError = (e: any) => {
    console.error("Connection failed: ", e);
  };
}

export const webHookStore = new WebHookStore();
