import { MapCardPlace } from "../../../model/Map";
import { Popover } from "../../../shared/components/ui/popover/Popover";
import { DeckPlace } from "./DeckPlace";
import { useDrag } from "react-dnd";
import { ReactComponent as CardIcon } from "../../../assets/icons/card.svg";
import { ReactComponent as CardsIcon } from "../../../assets/icons/cards.svg";
import { DeckPicker } from "./DeckPicker";
import { IconButton } from "@mui/material";
import { useMaps } from "../../map-hex/components/MapProvider";
import { cuni } from "../../../shared/services/cuni/cuni";
import { CharacterCardPanel, GameDeck } from "../../../model/Game";
import CancelIcon from "@mui/icons-material/Cancel";
import { cardUtils } from "../../../shared/utils/cardUtils";
import { useCards } from "../../chat/hooks/useCards";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import { MapHexDrop } from "../../map-hex/MapHexDrop";
import ShuffleOnIcon from "@mui/icons-material/ShuffleOn";
import { useGame } from "../../character/hooks/useGame";
import { getGameDbList } from "../../tokens/hooks/useGameDb";
import {
  mapGameDbAsPanel,
  updatePanel,
} from "../../tokens/hooks/useGameDbPanels";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";

export interface DeckPlaceContainerProps {
  deck: MapCardPlace;
}
export const DeckPlaceContainer = (props: DeckPlaceContainerProps) => {
  const { deck: deckPlace } = props;

  const [{ isDragging: isDraggingSingle }, dragRef] = useDrag(
    () => ({
      type: "TOKEN-CARD",
      item: (() => {
        const drag: MapHexDrop = {
          deck: deckPlace,
          type: "FROM_TABLE",
        };
        return drag;
      })(),
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0 : 1,
        isDragging: monitor.isDragging(),
      }),
    }),
    [],
  );
  const [{ isDragging: isDraggingMultiple }, multipleDragRef] = useDrag(
    () => ({
      type: "TOKEN-CARD",
      item: (() => {
        const drag: MapHexDrop = {
          deck: deckPlace,
          type: "FROM_TABLE",
          multiple: true,
        };
        return drag;
      })(),
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0 : 1,
        isDragging: monitor.isDragging(),
      }),
    }),
    [],
  );

  const isDragging = isDraggingMultiple || isDraggingSingle;
  const { all, current } = useMaps();
  const cards = useCards();

  const onDeckChange = (deck: GameDeck) => {
    if (!current) return;
    const dbItem = current.deckPlaces.find((x) => x.key === deckPlace.key);
    if (!dbItem) return;
    dbItem.deckKey = deck.key;
    // TODO: it should clean UP like shuffle ALL
    dbItem.cards = cardUtils.generate(
      cards.filter((x) => x.deckKey === deck.key),
    );
    cuni.updateGame("maps", all);
  };

  const deleteDeckPlace = () => {
    if (!current) return;
    current.deckPlaces = current.deckPlaces.filter(
      (x) => x.key !== deckPlace.key,
    );
    cuni.updateGame("maps", all);
  };

  const takeFromTable = () => {
    if (!current) throw new Error("NO current map");
    const cardIntances: { key: string; cardKey: string }[] = current.deckPlaces
      .filter((x) => x.deckFlyweightKey === deckPlace.key && x.type === "card")
      .map((x) => ({
        key: x.key,
        cardKey: x.card?.key as string,
      }))
      .filter((x) => x.cardKey);
    const deckCardInstanceKeys: string[] = cardIntances.map((x) => x.key);
    current.deckPlaces = current.deckPlaces.filter(
      (x) => !deckCardInstanceKeys.includes(x.key || ""),
    );
    return cardIntances;
  };
  const deckShuffle = () => {
    if (!current) return;
    const cardIntances = takeFromTable();
    const currentPlace = current.deckPlaces.find(
      (x) => x.key === deckPlace.key,
    );
    if (!currentPlace) return;
    currentPlace.cards = cardUtils.shuffle([
      ...currentPlace.cards,
      ...cardIntances.map((x) => x.cardKey),
    ]);
    cuni.updateGame("maps", all);
  };

  const { game } = useGame();
  const deckShuffleAll = () => {
    if (!current) return;
    const currentPlace = current.deckPlaces.find(
      (x) => x.key === deckPlace.key,
    );
    if (!currentPlace) return;
    // 1. Find all cards in all hands
    // 2. Remove cards from hands
    const { list: dbList } = getGameDbList(game);
    dbList
      .map(mapGameDbAsPanel)
      .flatMap((x) => x)
      .forEach((panel) => {
        if (panel.__type !== "card") return;
        if (
          !panel.cards.some((card) => card.deckFlyweightKey === deckPlace.key)
        )
          return;

        updatePanel(panel.key, (state: CharacterCardPanel) => {
          state.cards = state.cards.filter(
            (x) => x.deckFlyweightKey !== deckPlace.key,
          );
          return state;
        });
      });

    // 3. create clean deck and shuffle
    takeFromTable();
    currentPlace.cards = cardUtils.generate(
      cards.filter((x) => x.deckKey === currentPlace?.deckKey),
    );
    cuni.updateGame("maps", all);
  };

  const rotate = () => {
    if (!current) return;
    const currentPlace = current.deckPlaces.find(
      (x) => x.key === deckPlace.key,
    );
    if (!currentPlace) return;
    currentPlace.rotate = (currentPlace.rotate || 0) + (90 % 360);
    cuni.updateGame("maps", all);
  };

  return (
    <Popover
      forceClose={isDragging}
      renderButton={(onClick) => (
        <DeckPlace deck={deckPlace} onClick={onClick} />
      )}
    >
      <div className="deck-place-container">
        <IconButton ref={dragRef}>
          <CardIcon style={{ width: 24, height: 24 }} />
        </IconButton>
        {deckPlace.type === "deck" && (
          <>
            <IconButton ref={multipleDragRef}>
              <CardsIcon style={{ width: 24, height: 24 }} />
            </IconButton>
            <DeckPicker onChange={onDeckChange} />
            <IconButton onClick={deckShuffle}>
              <ShuffleIcon />
            </IconButton>
            <IconButton onClick={deckShuffleAll}>
              <ShuffleOnIcon />
            </IconButton>
          </>
        )}
        <IconButton onClick={rotate}>
          <RotateLeftIcon />
        </IconButton>
        <IconButton onClick={deleteDeckPlace}>
          <CancelIcon color="error" />
        </IconButton>
      </div>
    </Popover>
  );
};
