import { Box, Button } from "@mui/material";
import { useGame } from "../character/hooks/useGame";
import { DeleteGameButton } from "../schema/components/DeleteGameButton";
import { BasicDataUpdate } from "./components/BasicDataUpdate";
import { CharacterNames } from "./components/CharacterNames";
import { GameCheckbox } from "./components/GameCheckbox";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import { useState } from "react";
import { AvatarView } from "./components/AvatarView";
import { SwitchView } from "../../shared/components/ui/switch-view/SwitchView";
import { useTranslation } from "react-i18next";
import { MusicView } from "./components/MusicView";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { LayoutView } from "./components/LayoutView";
import { isPreview } from "../../config";

export const GameGeneralView = () => {
  const { game } = useGame();
  const { t } = useTranslation();
  const [view, setView] = useState<string | null>(null);

  return (
    <SwitchView
      setView={setView}
      view={view}
      viewComponents={{
        avatar: <AvatarView />,
        music: <MusicView />,
        layout: <LayoutView />,
      }}
    >
      <BasicDataUpdate />
      <CharacterNames />
      <Box display="flex" flexDirection="column">
        <GameCheckbox
          label={t("GAME.SHOW_USER_CHAT")}
          parameter="showUserChat"
        />
        <GameCheckbox
          label={t("GAME.SHOW_CHAPTERS")}
          parameter="showChapters"
        />
        <GameCheckbox
          label={t("GAME.PLAYERS_EDIT_THEMSELF")}
          parameter="playerEditSelf"
        />
      </Box>
      <Button
        disabled={isPreview}
        variant="outlined"
        startIcon={<DashboardIcon />}
        onClick={() => setView("layout")}
      >
        {t("GAME.CONFIGURE_LAYOUT")}
      </Button>
      <Button
        variant="outlined"
        startIcon={<MusicNoteIcon />}
        onClick={() => setView("music")}
      >
        {t("GAME.CONFIGURE_MUSIC")}
      </Button>
      <Button
        variant="outlined"
        startIcon={<InsertEmoticonIcon />}
        onClick={() => setView("avatar")}
      >
        {t("GAME.CONFIGURE_AVATARS")}
      </Button>
      <DeleteGameButton game={game} />
    </SwitchView>
  );
};
