import CasinoIcon from "@mui/icons-material/Casino";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import PublicIcon from "@mui/icons-material/Public";
import MapIcon from "@mui/icons-material/Map";
import { Tab, Tabs } from "@mui/material";
import React from "react";
import { useGame } from "../character/hooks/useGame";

interface ChatPanelProps {
  value: number;
  setValue: (value: number) => void;
}

export const ChatPanel = (props: ChatPanelProps) => {
  const { value, setValue } = props;

  const { game } = useGame();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <div className="paper paper__tab chat__panel">
      <Tabs
        value={value}
        onChange={handleChange}
        scrollButtons="auto"
        variant="scrollable"
      >
        <Tab icon={<PublicIcon />} aria-label="global" />
        {game.root?.showUserChat && (
          <Tab icon={<PeopleOutlineIcon />} aria-label="user" />
        )}
        <Tab icon={<PeopleAltIcon />} aria-label="character" />
        <Tab icon={<CasinoIcon />} aria-label="dice" />
        <Tab icon={<MapIcon />} aria-label="map" />
      </Tabs>
    </div>
  );
};
