import React, { useMemo } from "react";
import { Button, Tooltip } from "@mui/material";
import { uuid } from "../../utils/uuid";
import { useUser } from "../../../features/auth/useUser";
import { t } from "i18next";
import { useAws } from "../../hooks/use-aws";

interface UploadButtonProps {
  label: string;
  onUpload: (key: string, location: string) => void;
}

const UploadButton = (props: UploadButtonProps) => {
  const { label, onUpload } = props;

  const user = useUser();

  const id = useMemo(() => uuid(), []);
  const { upload } = useAws();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (user?.licensePlan === "Free") return;
    if (event.target.files) {
      const filesArray = Array.from(event.target.files);
      upload(filesArray, onUpload);
    }
  };

  const handleUpload = () => {
    document.getElementById(id)?.click();
  };

  return (
    <Tooltip title={t("INFO.UPGRADE")}>
      <div style={{ display: "flex" }}>
        <input
          accept="image/*,audio/mp3"
          type="file"
          id={id}
          multiple
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
        <Button
          style={{
            flex: 1,
          }}
          disabled={user?.licensePlan === "Free"}
          variant="contained"
          onClick={handleUpload}
        >
          {label}
        </Button>
      </div>
    </Tooltip>
  );
};

export default UploadButton;
