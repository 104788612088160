import React from "react";

export interface AuthData {
  expiration: string;
  token: string;
  userId: number;
  email: string;
  userName: string;
  roles: string[];
  needCodeValidaton: boolean;
  licensePlan: 'Free';
  licenseExpiredDate: string;
}

export interface RegisterDataInput {
  username: string;
  email: string;
  password: string;
}

export interface RegisterData extends AuthData {}

export interface IdentityError {
  code: string;
  description: string;
}

export interface IdentityResult {
  succeeded: boolean;
  errors: IdentityError[];
}

export interface AuthModel {
  data?: AuthData;
  setData: (data?: AuthData) => void;
}

export const AuthContext = React.createContext<AuthModel>({
  setData: () => {},
});
