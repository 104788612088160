import { useEffect, useRef, useState } from "react";
import "./MapHex.css";
import { Conditional } from "../../shared/components/logic/Conditional";
import { IconButton } from "@mui/material";
import PanToolIcon from "@mui/icons-material/PanTool";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import { HexRow } from "./components/Hex";
import { MapActions } from "./components/MapActions";
import { MapProvider, useMaps } from "./components/MapProvider";
import { HexActionProvider } from "./components/HexActionProvider";
import { SquareRow } from "./components/Square";
import MapInteractionCSS from "../../shared/components/ui/map-interaction/src/MapInteractionCSS";
import { TokenLink } from "./components/TokenLink";
import { Popover } from "../../shared/components/ui/popover/Popover";
import { TokenView } from "../tokens/TokenDetailEditView";
import { DeckPlaceContainer } from "../cards/components/DeckPlaceContainer";
import { useDrop } from "react-dnd";
import { useMergedRefs } from "../../shared/hooks/useMergedRefs";
import { CharacterCardPanel, GameCard } from "../../model/Game";
import { uuid } from "../../shared/utils/uuid";
import { cuni } from "../../shared/services/cuni/cuni";
import { updatePanel } from "../tokens/hooks/useGameDbPanels";
import { MapHexDrop } from "./MapHexDrop";
import { cardUtils } from "../../shared/utils/cardUtils";
import { useGame } from "../character/hooks/useGame";
import { useCards } from "../chat/hooks/useCards";
import { DiceViewContainer } from "../dices/DiceViewContainer";
import { getTopIndex } from "../dices/use-map-drag";
import GestureIcon from "@mui/icons-material/Gesture";
import {
  MapDrawingLayer,
  MapDrawingLayerType,
} from "./components/MapDrawingLayer";
import { ColorPicker } from "../../shared/components/ui/color-picker/ColorPicker";
import LayersClearIcon from "@mui/icons-material/LayersClear";
import { mapUtils } from "./mapUtils";
import { MapImagesView } from "./components/MapImagesView";

export const MapHexDisplay = () => {
  const mapRef = useRef<HTMLDivElement>(null);

  const [loading, setLoading] = useState(true);
  const [size, setSize] = useState<{ width: number; height: number }>();
  const [transform, setTransform] = useState<{
    scale: number;
    translation: { x: number; y: number };
  }>({
    scale: 1,
    translation: { x: 0, y: 0 },
  });

  useEffect(() => {
    setTimeout(() => {
      if (!mapRef.current) return;
      setLoading(false);
      setSize({
        height: 10000,
        width: 10000,
      });
    }, 10);
  }, []);

  const { game } = useGame();
  const { current, all } = useMaps();
  const cards = useCards();

  const [{ isOver }, drop] = useDrop({
    accept: "TOKEN-CARD",
    drop: (drop: MapHexDrop, monitor) => {
      if (!current) return;
      const { top, left } = mapRef.current!.getBoundingClientRect();
      const { x, y } = monitor.getClientOffset()!;
      const transformData = mapUtils.getTransformDataFromMapContainer();

      const newCoordinates = mapUtils.calculateNewCoordinates(
        x - left,
        y - top,
        transformData,
      );

      let card: GameCard | undefined = undefined;
      let deckFlyweightKey: string = "";
      if (drop.type === "FROM_TABLE") {
        const { deck } = drop;
        const cardId = cardUtils.pickFromDeck(game, deck.key);
        card = cards.find((x) => x.key === cardId);
        deckFlyweightKey = deck.key;
      } else if (drop.type === "FROM_HAND") {
        card = drop.card;
        deckFlyweightKey = drop.instance.deckFlyweightKey;
      } else {
        throw new Error("Weird type");
      }

      current.deckPlaces ??= [];
      current.deckPlaces.push({
        key: uuid(),
        type: "card",
        cards: [],
        card,
        deckFlyweightKey,
        position: { ...newCoordinates, zIndex: getTopIndex(current) },
      });
      cuni.updateGame("maps", all);

      if (drop.type === "FROM_HAND") {
        const { instance, panel } = drop;
        panel.cards = panel.cards.filter((x) => x.key !== instance.key);
        updatePanel(panel.key, (state: CharacterCardPanel) => {
          state.cards = state.cards.filter((x) => x.key !== instance.key);
          return state;
        });
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });
  const mergedRef = useMergedRefs(drop, mapRef);

  const drawLayer = useRef<MapDrawingLayerType>(null);
  const [disabled, setDisabled] = useState(false);
  const [color, setColor] = useState<string>();
  return (
    <div
      ref={mergedRef}
      className="map"
      style={{
        border: isOver ? "2px solid gray" : undefined,
      }}
    >
      <MapDrawingLayer ref={drawLayer} color={color} />

      <div className="map__toolbar">
        <IconButton onClick={() => setDisabled(true)}>
          <TouchAppIcon />
        </IconButton>
        <IconButton>
          <PanToolIcon
            onClick={() => {
              setDisabled(false);
              setColor(undefined);
            }}
          />
        </IconButton>
        <ColorPicker
          //@ts-ignore
          Icon={GestureIcon}
          selected={color}
          onColorChange={setColor}
        />
        <IconButton>
          <LayersClearIcon onClick={() => drawLayer.current?.clear()} />
        </IconButton>
      </div>
      <MapInteractionCSS
        containerRef={drop}
        showControls
        disableZoom={disabled}
        disablePan={disabled}
        value={transform}
        onChange={(value: any) => setTransform(value)}
      >
        <div className="map__container">
          <Conditional condition={!loading}>
            <Conditional
              condition={current}
              success={(mapConfig) => (
                <HexActionProvider>
                  <div
                    className="map__container-inner"
                    style={{
                      ...size,
                      backgroundImage: `url(${mapConfig.background})`,
                    }}
                  >
                    <MapImagesView />
                    {current?.tokens?.map((token) => (
                      <Popover
                        renderButton={(onClick) => (
                          <TokenLink token={token} onClick={onClick} />
                        )}
                      >
                        <TokenView token={token} />
                      </Popover>
                    ))}
                    {current?.dices?.map((dice) => (
                      <DiceViewContainer dice={dice} />
                    ))}
                    {current?.deckPlaces?.map((deck) => (
                      <DeckPlaceContainer deck={deck} />
                    ))}
                    {mapConfig.grid.map((row) =>
                      mapConfig.isSquare ? (
                        <SquareRow
                          row={row}
                          hasBackground={Boolean(mapConfig.background)}
                        />
                      ) : (
                        <HexRow
                          row={row}
                          hasBackground={Boolean(mapConfig.background)}
                        />
                      ),
                    )}
                  </div>
                </HexActionProvider>
              )}
            ></Conditional>
          </Conditional>
        </div>
      </MapInteractionCSS>
    </div>
  );
};

export const MapHex = () => {
  return (
    <MapProvider admin>
      <div className="mapHex">
        <MapActions />
        <MapHexDisplay />
      </div>
    </MapProvider>
  );
};
