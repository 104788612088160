import React, { useRef, useState } from "react";

import { observer } from "mobx-react";
import { webHookStore } from "../../store/WebHookStore";
import { UserTextMessage, WebHookSubType } from "../../model/WebHookMessage";
import { ChatPanel } from "./ChatPanel";
import { ChatMessageView } from "./components/chat-message-view/ChatMessageView";
import { MapHexDisplay } from "../map-hex/MapHex";
import { MapProvider } from "../map-hex/components/MapProvider";
import { ChatSessions } from "./components/chat-sessions/ChatSessions";
// import { CircularProgress } from "@mui/material";
import { useGame } from "../character/hooks/useGame";
import ChatInput, { IChatInput } from "./ChatInput";

interface IChatWindow extends Pick<IChatInput, "sendMessage"> {
  chat: UserTextMessage[];
}

const lookup: (WebHookSubType | null)[] = [
  null,
  "USER_CHAT",
  "CHARACTER_CHAT",
  "ACTION_ROLL",
];

const ChatWindow: React.FC<IChatWindow> = (props: IChatWindow) => {
  const [value, setValue] = useState(0);

  let lookupKey = lookup.filter((x) => x !== "USER_CHAT")[value];
  let showMap = value === 3;
  const { game } = useGame();
  if (game.root?.showUserChat) {
    showMap = value === 4;
    lookupKey = lookup[value];
  }

  const chat = props.chat
    .filter((x) => lookupKey === null || x.subType === lookupKey)
    .map((m) => <ChatMessageView key={m.id} {...m} />);

  const horizontalListContainer = useRef<HTMLDivElement>(null);
  const loaderRef = useRef<HTMLDivElement>(null);

  return (
    <React.Fragment>
      <ChatSessions scrollRef={horizontalListContainer} loaderRef={loaderRef} />
      <ChatPanel value={value} setValue={setValue} />
      {showMap ? (
        <MapProvider>
          <MapHexDisplay />
        </MapProvider>
      ) : (
        <>
          {" "}
          <div ref={horizontalListContainer} className="paper chat__window">
            {/* <div ref={loaderRef}>
        <CircularProgress />
      </div> */}
            {chat}
          </div>
          <ChatInput sendMessage={props.sendMessage} />
        </>
      )}
    </React.Fragment>
  );
};

export default observer((props: Pick<IChatInput, "sendMessage">) => (
  <ChatWindow {...props} chat={webHookStore.chat} />
));
