import { Checkbox, FormControlLabel } from "@mui/material";
import { useGame } from "../../character/hooks/useGame";
import { cuni } from "../../../shared/services/cuni/cuni";

interface GameCheckboxProps {
  parameter:
    | "showUserChat"
    | "playerEditSelf"
    | "showChapters"
    | "overrideAvatars"
    | "overrideMusic"
    | "hidePlayerActions";

  label: string;
}

export const GameCheckbox = (props: GameCheckboxProps) => {
  const { parameter, label } = props;
  const { game } = useGame();
  if (!game.root) return null;
  const value = game.root[parameter];
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={value}
          value={value}
          onChange={() => {
            cuni.updateGame(parameter, !value);
          }}
        />
      }
      label={label}
    />
  );
};
