import { GameCard } from "../../model/Game";
import { clsx } from "../../shared/utils/clsx";

interface CardViewProps {
  name?: string;
  avatarUrl?: string;
  isDragging?: boolean;
  dragRef?: any;
  uiType?: GameCard["uiType"];
  color?: string;
  textColor?: string;
}

export const CardView = (props: CardViewProps) => {
  const { isDragging, name, avatarUrl, dragRef, uiType, color, textColor } =
    props;
  return (
    <div
      ref={dragRef}
      className={clsx("card-view", {
        [`uiType-${uiType}`]: !!uiType,
      })}
      style={{
        backgroundColor: color,
        color: textColor,
        borderColor: isDragging ? "gray" : "white",
        backgroundImage: `url(${avatarUrl})`,
      }}
    >
      {avatarUrl ? "" : name}
    </div>
  );
};
