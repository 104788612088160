import { useGame } from "../../character/hooks/useGame";
import { Popover } from "../../../shared/components/ui/popover/Popover";
import { Button, IconButton } from "@mui/material";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import { GameDeck } from "../../../model/Game";

interface DeckPickerProps {
  onChange: (deck: GameDeck) => void;
}
export const DeckPicker = (props: DeckPickerProps) => {
  const { onChange } = props;
  const { game } = useGame();
  const decks = game.root.settings.decks;

  return (
    <Popover
      renderButton={(onClick) => (
        <IconButton onClick={onClick}>
          <GroupWorkIcon />
        </IconButton>
      )}
    >
      {decks.map((deck) => (
        <Button key={deck.key} size="small" onClick={() => onChange(deck)}>
          {deck.name}
        </Button>
      ))}
    </Popover>
  );
};
