import { observer } from "mobx-react";
import { FormProvider, useForm } from "react-hook-form";
import { GameProvider, useGame } from "../features/character/hooks/useGame";
import { GameSchemaMenu } from "../features/schema/GameSchemaMenu";
import { Character } from "../model/Game";
import { rootStore } from "../store/RootStore";
import "./Page.css";
import { GameTabEditView } from "../features/schema/GameTabEditView";
import { useEffect } from "react";
import { UserOption } from "../model/User";
import { useFriends } from "../features/invitation/FriendList";
import Chat from "../features/chat/Chat";
import { Conditional } from "../shared/components/logic/Conditional";
import { GameGeneralView } from "../features/game-general/GameGeneralView";
import { MapHex } from "../features/map-hex/MapHex";
import { GameTabSectionEditView } from "../features/schema/GameTabSectionEditView";
import DesktopOnlyPlaceholder from "../shared/components/ui/DektopOnlyPlaceholder";
import { useBackgroundImage } from "../shared/hooks/useBackgoundImage";
import { GmToolbox } from "../features/gm-toolbox/GmToolbox";
import { TokenEditView } from "../features/tokens/TokenEditView";
import { CardEditView } from "../features/cards/CardEditView";
import { DeckEditView } from "../features/decks/DeckEditView";
import { DiceEditView } from "../features/dices/DiceEditView";
import { UIDatabaseView } from "../features/ui-database/UIDatabaseView";

export const GameEditPageInternal = () => {
  const form = useGameEditPage();
  const menu = form.watch("menu");
  useBackgroundImage();
  return (
    <FormProvider {...form}>
      <div className="page side-bar-page">
        <GameSchemaMenu showCharacter={menu === "Tabs"} />
        <Conditional condition={menu === "General"}>
          <GameGeneralView />
        </Conditional>
        <Conditional condition={menu === "Tabs"}>
          <GameTabEditView />
        </Conditional>
        <Conditional condition={menu === "Chat"}>
          <Chat />
        </Conditional>
        <Conditional condition={menu === "Map"}>
          <MapHex />
        </Conditional>
        <Conditional condition={menu === "Sections"}>
          <GameTabSectionEditView />
        </Conditional>
        <Conditional condition={menu === "Tokens"}>
          <TokenEditView />
        </Conditional>
        <Conditional condition={menu === "Cards"}>
          <CardEditView />
        </Conditional>
        <Conditional condition={menu === "Decks"}>
          <DeckEditView />
        </Conditional>
        <Conditional condition={menu === "Dices"}>
          <DiceEditView />
        </Conditional>
        <Conditional condition={menu === "Database"}>
          <UIDatabaseView />
        </Conditional>
      </div>
      <GmToolbox />
    </FormProvider>
  );
};

// TODO: validate query parameter & skip querying messages on Edit
export const GameEditPage = observer(() => {
  return (
    <GameProvider game={rootStore.game}>
      <GameEditPageInternal />
      <DesktopOnlyPlaceholder />
    </GameProvider>
  );
});

export interface GameEditFormValues {
  selected?: Character;
  user: UserOption | null;
  menu?:
    | "Tabs"
    | "General"
    | "Chat"
    | "Map"
    | "Sections"
    | "Tokens"
    | "Cards"
    | "Decks"
    | "Dices"
    | "Database";
}

// TODO: move this hook
export const useGameEditPage = () => {
  const { game } = useGame();

  const { options: friends } = useFriends(true);

  const form = useForm<GameEditFormValues>({
    defaultValues: {
      menu: "General",
      selected: game.characters ? Object.values(game.characters)[0] : undefined,
      user: game.characters
        ? friends.find(
            (x) => x.key === Object.values(game.characters)[0]?.__userId,
          )
        : null,
    },
  });

  useEffect(() => {
    const current = form.watch("selected");
    const characters = Object.values(game.characters);
    const newChar = current
      ? characters.find((x) => x.key === current.key)
      : characters[0];
    form.setValue("selected", newChar);
    const user = friends.find((x) => x.key === newChar?.__userId);
    form.setValue("user", user ?? null);
  }, [form, game, friends]);

  const character = form.watch("selected");

  useEffect(() => {
    const user = friends.find((x) => x.key === character?.__userId);
    form.setValue("user", user ?? null);
  }, [character, form, friends]);

  return form;
};
