import { Button, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import { ModalProps } from "../../../shared/components/modal/ModalProps";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { config } from "../../../config";
import { apiClient } from "../../../shared/services/apiClient";
import { useTranslation } from "react-i18next";

type GameModalProps = ModalProps;

// TODO
// 1. Consider load old surveys
// 2. Consider load old actions
// 3. Consider make a chain of consequences of actions (some day after...)
// 4. Quick action checkbox
// 5. Adress survey via Message ReceiverId

interface CreateGameForm {
  name: string;
  url?: string;
}

export const CreateGameModal = (props: GameModalProps) => {
  const { open, handleClose } = props;
  const { t } = useTranslation();

  const { register, handleSubmit, reset } = useForm<CreateGameForm>({
    defaultValues: {},
  });

  const onClose = () => {
    reset();
    handleClose();
  };

  const queryClient = useQueryClient();

  const { mutate } = useGamePost();
  const onSubmit = (form: CreateGameForm) => {
    mutate(form, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["game-all"],
          exact: true,
        });
      },
    });
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-avatar-title"
      aria-describedby="modal-avatar-description"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className="modal">
          <Typography variant="h6" component="h2">
            {t("GAME.GAME")}
          </Typography>
          <Typography id="modal-avatar-description" sx={{ mt: 2 }}>
            {t("GAME.CREATE_MODAL_INFO")}
          </Typography>

          <TextField label={t('GAME.GAME_NAME')} {...register("name")} />
          <TextField label={t('GAME.GAME_WALLPAPER_URL')} {...register("url")} />

          <Button type="submit" variant="contained">
            {t("GAME.CREATE")}
          </Button>
          <Button onClick={onClose}>{t("COMMON.CLOSE")}</Button>
        </Box>
      </form>
    </Modal>
  );
};

export const useGamePost = () => {
  return useMutation({
    mutationFn: (data: CreateGameForm) =>
      apiClient.post<CreateGameForm>(`${config.baseUrl}/game`, data),
  });
};
