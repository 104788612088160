import { Typography, Button } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { GameToken, SETTINGS_ADMIN_KEY } from "../../model/Game";
import { cuni } from "../../shared/services/cuni/cuni";
import { uuid } from "../../shared/utils/uuid";
import { useGame } from "../character/hooks/useGame";
import { QuickEditModalProvider } from "../quick-edit/hooks/useQuickEdit";
import { Table } from "../../shared/components/ui/Table";
import { SwitchView } from "../../shared/components/ui/switch-view/SwitchView";
import { TokenDetailEditView } from "./TokenDetailEditView";

export const TokenEditView = () => {
  const { game } = useGame();
  const { t } = useTranslation();

  const upload = useCallback(
    (key: string) => {
      const rows = game.root.settings?.tokens || [];
      rows.push({
        key,
        connections: [],
        actions: [],
      });
      cuni.update(SETTINGS_ADMIN_KEY, "tokens", rows);
    },
    [game],
  );

  const add = useCallback(() => upload(uuid()), [upload]);
  const [view, setView] = useState<string | null>(null);

  const [value, setValue] = useState<GameToken>();
  const [tokenIndex, setTokenIndex] = useState<number>();

  // Update view when token is updated
  useEffect(() => {
    if (!value) return;
    const token = game.root.settings.tokens.find((x) => x.key === value.key);
    setValue(token);
  }, [game, value]);

  const { rows, headers } = useMemo(() => {
    const items = game.root.settings?.tokens || [];
    const headers = [
      {
        key: "key",
        title: "Token",
        cell: (value: GameToken) => (value.name ? value.name : value.key),
      },
      {
        key: "action",
        title: t("COMMON.ACTION"),
        cell: (value: GameToken, index: number) => (
          <Button
            onClick={() => {
              setValue(value);
              setTokenIndex(index);
              setView("detail");
            }}
          >
            {t("COMMON.OPEN")}
          </Button>
        ),
      },
    ];
    const rows = items.map((x) => ({
      ...x,
      action: "",
    }));
    return { rows, headers };
  }, [game, t]);

  return (
    <QuickEditModalProvider
      character={{ ...game.root.settings, key: SETTINGS_ADMIN_KEY }}
    >
      <SwitchView
        setView={setView}
        view={view}
        viewComponents={{
          detail: (
            <TokenDetailEditView tokenIndex={tokenIndex!} token={value!} />
          ),
        }}
      >
        <div className="column" style={{ paddingRight: "1rem" }}>
          <Typography>{t("TOKEN.OVERRIDE_INFO")}</Typography>
          <Button variant="outlined" onClick={add}>
            {t("TOKEN.ADD")}
          </Button>
          <Table
            titleSufix="tokens"
            directList
            root={{ ...game.root.settings, key: SETTINGS_ADMIN_KEY }}
            headers={headers}
            className="paper-outline"
            rows={rows}
          />
        </div>
      </SwitchView>
    </QuickEditModalProvider>
  );
};
