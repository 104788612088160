import { Resource } from "../../model/Resource";
import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../../shared/services/apiClient";
import { config } from "../../config";
import { useUser } from "../../features/auth/useUser";

export const useResources = () => {
  const user = useUser();
  return useQuery({
    queryKey: ["resource-all"],
    queryFn: () =>
      apiClient
        .get<Resource[]>(`${config.baseUrl}/api/resource/`)
        .then((res) => res.data),
  });
};

//?Where=GameId == ${gameId}&Where=ReceiverId == ${user?.id} or ReceiverId == null
