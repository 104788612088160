import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { useMemo } from "react";
import { ModalProps } from "../../../../shared/components/modal/ModalProps";
import { useGame } from "../../hooks/useGame";
import { Table } from "../../../../shared/components/ui/Table";
import { Interaction } from "../../../../model/Game";
import { cuni } from "../../../../shared/services/cuni/cuni";
import { WebHookType } from "../../../../model/WebHookMessage";
import { useTranslation } from "react-i18next";
type InteractionModalProps = ModalProps;

export const InteractionModal = (props: InteractionModalProps) => {
  const { open, handleClose } = props;
  const { t } = useTranslation();
  const { game } = useGame();

  const { rows, headers } = useMemo(() => {
    if (!game.me) throw new Error("Game should be already in place");
    const interactions = game?.me.__interactions ?? [];
    const headers = [
      {
        key: "desc",
        title: t("COMMON.DESC"),
      },
      {
        key: "cost",
        title: t("INTERACTION.COST"),
      },
      {
        key: "action",
        title: t("COMMON.ACTION"),
        cell: (value: Interaction) => (
          <Button
            onClick={() => {
              cuni.pushMessage(() => {
                return {
                  type: WebHookType.UserInteraction,
                  subType: "USER_INPUT_INTERACTION",
                  message: value.key,
                  metaData: {
                    charId: game.me.key,
                  },
                };
              });
            }}
          >
            {t("COMMON.EXECUTE")}
          </Button>
        ),
      },
    ];
    const rows = interactions.map((x) => ({
      ...x,
      action: "",
    }));
    return { rows, headers };
  }, [game.me, t]);

  if (!game.me) return null;

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className="modal">
        <Typography variant="h6" component="h2">
          {t("INTERACTION.INTERACTIONS")}
        </Typography>
        <Typography id="modal-avatar-description" sx={{ mt: 2 }}>
          {t("INTERACTION.DESC")}
        </Typography>
        <Table headers={headers} rows={rows} />
        <Button onClick={handleClose}>{t("COMMON.CLOSE")}</Button>
      </Box>
    </Modal>
  );
};
