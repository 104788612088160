import { PropsWithChildren } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Layout from "./components/Layout";
import ErrorPage from "./components/ErrorPage";
import { GamePage } from "../../../pages/GamePage";
import { LoginPage } from "../../../pages/LoginPage";
import { ProtectedRoute } from "../../../features/auth/ProtectedRoute";
import { GameListPage } from "../../../pages/GameListPage";
import { GameEditPage } from "../../../pages/GameEditPage";
import { ProfilePage } from "../../../pages/ProfilePage";
import { RegisterPage } from "../../../pages/RegisterPage";
import { CodeConfirmPage } from "../../../pages/CodeConfirmPage";
import { ForgotPasswordPage } from "../../../pages/ForgotPassword";
import { UpdatePasswordPage } from "../../../pages/UpdatePassword";
import { GlossaryPage } from "../../../pages/GlossaryPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: (
          <ProtectedRoute>
            <GamePage />
          </ProtectedRoute>
        ),
      },
      {
        path: "/game/:id/modify",
        element: (
          <ProtectedRoute>
            <GameEditPage />
          </ProtectedRoute>
        ),
      },
      {
        path: "login",
        element: <LoginPage />,
      },
      {
        path: "register",
        element: <RegisterPage />,
      },
      {
        path: "confirm",
        element: <CodeConfirmPage />,
      },
      {
        path: "forgot-password",
        element: <ForgotPasswordPage />,
      },
      {
        path: "update-password",
        element: <UpdatePasswordPage />,
      },
      {
        path: "list",
        element: (
          <ProtectedRoute>
            <GameListPage />
          </ProtectedRoute>
        ),
      },
      {
        path: "profile",
        element: (
          <ProtectedRoute>
            <ProfilePage />
          </ProtectedRoute>
        ),
      },
      {
        path: "glossary",
        element: (
          <ProtectedRoute>
            <GlossaryPage />
          </ProtectedRoute>
        ),
      },
    ],
  },
]);

export const AppRouter = (props: PropsWithChildren) => {
  return <RouterProvider router={router} />;
};
