import { Game, GameCard } from "../../model/Game";
import { MapCardPlace } from "../../model/Map";

export function shuffle<T>(array: T[]) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

// We don't have stack in here
// const popOrShuffle = (game: IUnoGame): IUnoCard => {
//   let card = game.deck.pop();
//   if (!card) {
//     const last = game.stack.pop();
//     game.deck = shuffle([...game.stack]);
//     game.stack = [last!];
//     card = game.deck.pop();
//   }
//   return card!;
// };

/**
 * Pick a card.
 */
const pick = (deck: MapCardPlace) => {
  return deck.cards.pop();
};

/**
 * Generate deck via cards multipled by card amount.
 */
const generate = (deck: GameCard[]): string[] => {
  return shuffle(
    deck.flatMap((x) =>
      Array.from(Array(x.amount ?? 1).keys()).map((y) => x.key),
    ),
  );
};

const pickFromDeck = (game: Game, deckKey: string) => {
  const current = game.root?.maps.find((x) => x.active);
  const dbDeckPanel = current?.deckPlaces.find((x) => x.key === deckKey);
  if (!dbDeckPanel) return;

  let card: string | undefined = undefined;
  if (
    dbDeckPanel.type === "card" &&
    dbDeckPanel.card?.key &&
    current?.deckPlaces
  ) {
    card = dbDeckPanel.card.key;
    current.deckPlaces = current.deckPlaces.filter(
      (x) => x.key !== dbDeckPanel.key,
    );
  } else {
    card = pick(dbDeckPanel);
  }
  return card;
};

export const cardUtils = { pickFromDeck, generate, shuffle };
