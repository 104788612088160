import { PropsWithChildren } from "react";
import { Button, Link } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useUser } from "../../../../features/auth/useUser";
import { Conditional } from "../../logic/Conditional";
import { useTranslation } from "react-i18next";

interface GameInfoProps {
  full?: boolean;
}

export const GameInfo = (props: PropsWithChildren<GameInfoProps>) => {
  const { children, full = true } = props;
  const { t } = useTranslation();
  const user = useUser();
  return (
    <div
      className="paper game-info"
      style={{ flexBasis: full ? "100%" : undefined }}
    >
      <div className="game-info-inner">
        <InfoIcon />
        <Conditional condition={user?.licensePlan === "Free"}>
          <>
            {t("INFO.FREE_LICENSE")}
            <br />
          </>
        </Conditional>
        {children}
      </div>
      <Conditional condition={user?.licensePlan === "Free"}>
        <Button
          variant="contained"
          LinkComponent={Link}
          href="https://buycoffee.to/hubertnosek"
        >
          {t("COMMON.UPGRADE")}
        </Button>
      </Conditional>
    </div>
  );
};
