// src/components/MusicPlayer.js
import { Modal, Box, Typography, Button } from "@mui/material";
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { gameStorage } from "../../../shared/services/storage";
import { Game } from "../../../model/Game";

const MusicPlayer = () => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [audio, setAudio] = useState(true);

  const playAudio = () => {
    if (audioRef.current) {
      audioRef.current.volume = 0.03;
      audioRef.current.play();
    }

    setAudio(false);
  };

  const game = gameStorage.get<Game>();
  const source = game?.root.overrideMusic
    ? game.root.settings.musics.find((x) => Boolean(x.active))?.url
    : "https://cuni.fra1.cdn.digitaloceanspaces.com/cuni.mp3"; //"/music/cuni.mp3";

  // TODO: make localstorage listener
  useEffect(() => {
    const listener = (event: any) => {
      if (audioRef.current && event.key === "cuni__music") {
        const music = JSON.parse(event.newValue) as { controls: boolean };
        audioRef.current.controls = music.controls;
      }
      if (audioRef.current && event.key === "cuni__game") {
        const game = JSON.parse(event.newValue) as Game;
        const source = game?.root.overrideMusic
          ? game.root.settings.musics.find((x) => Boolean(x.active))?.url
          : "https://cuni.fra1.cdn.digitaloceanspaces.com/cuni.mp3"; //"/music/cuni.mp3";

        if (source && audioRef.current.src !== source)
          audioRef.current.src = source;
      }
    };
    document.addEventListener("localStorageChange", listener);
    return () => {
      document.removeEventListener("localStorageChange", listener);
    };
  }, []);
  return (
    <>
      <audio
        autoPlay
        loop
        ref={audioRef}
        style={{
          position: "fixed",
          top: 5,
          left: 200,
          zIndex: 100,
        }}
      >
        <source src={source} type="audio/mp3" />
        Your browser does not support the audio element.
      </audio>
      <Modal open={audio} onClose={playAudio}>
        <Box className="modal">
          <Typography variant="h6" component="h2">
            {t("MUSIC.MUSIC")}
          </Typography>
          <Typography>{t("MUSIC.QUESTION")}</Typography>
          <Button onClick={playAudio}>{t("GAME.PLAY")}</Button>
        </Box>
      </Modal>
    </>
  );
};

export default MusicPlayer;
