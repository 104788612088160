import React, { useState } from "react";
import "./DrawerPanel.css";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

interface DrawerPanelProps {
  width?: string;
  height?: string;
  anchor?: "left" | "right" | "top" | "bottom";
  children: React.ReactNode;
}

const DrawerPanel: React.FC<DrawerPanelProps> = ({
  width = "250px",
  height = "250px",
  anchor = "right",
  children,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const panelStyles: React.CSSProperties = {
    width:
      anchor === "left" || anchor === "right" ? (isOpen ? width : "0") : "100%",
    height:
      anchor === "top" || anchor === "bottom"
        ? isOpen
          ? height
          : "0"
        : "100%",
    overflowX: "hidden",
    overflowY: "hidden",
    transition: "width 0.3s ease, height 0.3s ease",
    position: "fixed",
    // backgroundColor: "#f4f4f4",
    // boxShadow:
    //   anchor === "left" || anchor === "right"
    //     ? "-2px 0 5px rgba(0, 0, 0, 0.5)"
    //     : "0px 2px 5px rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
    top: anchor === "top" ? 0 : anchor === "bottom" ? "auto" : 0,
    bottom: anchor === "bottom" ? 0 : anchor === "top" ? "auto" : 0,
    left: anchor === "left" ? 0 : "auto",
    right: anchor === "right" ? 0 : "auto",
    maxWidth: "100vw",
    maxHeight: "100vh",
  };

  const buttonStyles: React.CSSProperties = {
    position: "fixed",
    top: "50%",
    right: anchor === "right" ? "-1.2rem" : "auto",
    left: anchor === "left" ? "-1.2rem" : "auto",
    transform: "translateY(-50%) rotate(-90deg)",
    transformOrigin: "center",
    color: "#fff",
    padding: "8px 16px",
    border: "none",
    cursor: "pointer",
    zIndex: 1100,
  };

  return (
    <div className="drawer-root">
      <button onClick={toggleDrawer} className="paper" style={buttonStyles}>
        {isOpen ? (
          <KeyboardDoubleArrowRightIcon
            style={{ transform: "rotate(90deg)" }}
          />
        ) : (
          <KeyboardDoubleArrowLeftIcon style={{ transform: "rotate(90deg)" }} />
        )}
      </button>
      <div className="drawer-panel" style={panelStyles}>
        {isOpen && <div className="drawer-content">{children}</div>}
      </div>
    </div>
  );
};

export default DrawerPanel;
