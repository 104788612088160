import { useCallback, useMemo } from "react";
import { GameCheckbox } from "./GameCheckbox";
import { Button, Checkbox, Typography } from "@mui/material";
import { Table } from "../../../shared/components/ui/Table";
import { QuickEditModalProvider } from "../../quick-edit/hooks/useQuickEdit";
import { SETTINGS_ADMIN_KEY, SettingsMusic } from "../../../model/Game";
import { useGame } from "../../character/hooks/useGame";
import { cuni } from "../../../shared/services/cuni/cuni";
import { uuid } from "../../../shared/utils/uuid";
import { useTranslation } from "react-i18next";
import UploadButton from "../../../shared/components/logic/UploadButton";

export const MusicView = () => {
  const { game } = useGame();
  const { t } = useTranslation();

  const uploadMusic = useCallback(
    (key: string, url: string) => {
      const musics = game.root.settings?.musics || [];
      musics.push({
        key,
        url,
        active: false,
      });
      cuni.update(SETTINGS_ADMIN_KEY, "musics", musics);
    },
    [game],
  );
  const add = useCallback(() => uploadMusic(uuid(), "URL"), [uploadMusic]);

  const { rows, headers } = useMemo(() => {
    const musics = game.root.settings?.musics || [];
    const headers = [
      {
        key: "url",
        title: "Url",
      },
      {
        key: "active",
        title: "Active",
        cell: (value: SettingsMusic) => (
          <div>
            <Checkbox
              checked={value.active}
              value={value}
              onChange={() => {
                const musics = game.root.settings?.musics || [];
                const index = musics.findIndex((x) => x.key === value.key);
                musics[index].active = !musics[index].active;
                cuni.update(SETTINGS_ADMIN_KEY, "musics", musics);
              }}
            />
          </div>
        ),
      },
    ];
    return { rows: musics, headers };
  }, [game]);

  return (
    <QuickEditModalProvider
      character={{ ...game.root.settings, key: SETTINGS_ADMIN_KEY }}
    >
      <div className="column" style={{ paddingRight: "1rem" }}>
        <GameCheckbox
          label={t("MUSIC.OVERRIDE_MUSIC")}
          parameter="overrideMusic"
        />
        <Typography>{t("MUSIC.OVERRIDE_INFO")}</Typography>
        <UploadButton label={t("MUSIC.UPLOAD")} onUpload={uploadMusic} />
        <Button variant="outlined" onClick={add}>
          {t("MUSIC.ADD")}
        </Button>
        <Table
          titleSufix="musics"
          directList
          root={{ ...game.root.settings, key: SETTINGS_ADMIN_KEY }}
          headers={headers}
          rows={rows}
          className="paper-outline"
        />
      </div>
    </QuickEditModalProvider>
  );
};
