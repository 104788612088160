import { TextField, Typography } from "@mui/material";
import { makeInternal } from "../../model/Game";
import DrawerPanel from "../../shared/components/ui/drawer/DrawerPanel";
import { CharacterTabProvider } from "../character/components/generic/CharacterTabProvider";
import { useGame } from "../character/hooks/useGame";
import { GameTabEditViewInternal } from "../schema/GameTabEditView";
import { GameEditProvider } from "../schema/hooks/useGameEdit";
import { QuickRoll } from "./components/quick-roll/QuickRoll";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { cuni } from "../../shared/services/cuni/cuni";
import { useGamePermission } from "../../shared/hooks/useGamePermission";

export const GmToolbox = () => {
  const { game } = useGame();
  const { t } = useTranslation();
  const [note, setNote] = useState("");

  const { hasGamePermission } = useGamePermission();

  useEffect(() => {
    if (!game.root?.gmNote) return;
    setNote(game.root.gmNote);
  }, [game.root?.gmNote]);

  if (!hasGamePermission("editPanel")) return null;

  const saveNote = () => {
    cuni.updateGame("gmNote", note.split(","));
  };

  if (!game) return null;
  return (
    <DrawerPanel anchor="right" width="800px">
      <div className="paper drawer">
        <QuickRoll />

        <Typography>{t("GM.NOTES")}</Typography>
        <TextField
          multiline
          rows={5}
          onChange={(e) => setNote(e.target.value)}
          value={note}
          onBlur={saveNote}
        />

        <Typography>{t("GM.DEMO")}</Typography>
        <GameEditProvider main={false} editEnabled generic>
          <CharacterTabProvider character={makeInternal(game.root.internal)}>
            <GameTabEditViewInternal
              style={{
                height: "unset",
                marginTop: "1rem",
                padding: "1rem",
                background: "rgba(200, 200, 200, 0.5)",
                borderRadius: "8px",
              }}
            />
          </CharacterTabProvider>
        </GameEditProvider>
      </div>
    </DrawerPanel>
  );
};
