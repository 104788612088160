import { GameType } from "./GameType";
import { UserTextMessage } from "./WebHookMessage";
import { Map } from "./Map";
import i18next from "i18next";

export type Game = DefaultGame;

export interface GameTokenAction {
  name?: string;
  type?: "self" | "other" | "free";
  formulaSoruce?: string;
  formulaTarget?: string;
  targetProperty?: string;
  chance?: string;
}
export interface GameTokenConnection {
  dbKey: number;
  panelKey: string;
}
export interface GameToken {
  key: string;
  name?: string;
  avatarUrl?: string;
  connections: GameTokenConnection[];
  actions: GameTokenAction[];
  position?: GamePosition;
}

export interface SettingsMusic {
  key: string;
  url: string;
  active: boolean;
}

export interface GameCard {
  key: string;
  // this should be between CardAndDeck actually :O
  amount?: number;
  name?: string;
  type: "CARD" | "TOKEN_CHIP";
  uiType: "FREE" | "SQUARE" | "ROUND" | "CARD";
  avatarUrl?: string;
  deckKey?: string;
  color?: string;
  textColor?: string;
}

export interface GameDeck {
  key: string;
  name?: string;
  reverseAvatarUrl?: string;
  uiType: GameCard["uiType"];
  color?: string;
  textColor?: string;
  cardTextColor?: string;
  cardColor?: string;
}

export interface GamePosition {
  zIndex?: number;
  width?: number;
  height?: number;
  x: number;
  y: number;
}

export interface GameDice {
  key: string;
  name?: string;
  color?: string;
  textColor?: string;
  fields?: string[];
  position?: GamePosition;
}

export interface CardFlyweight {
  key: string;
  deckFlyweightKey: string;
  cardKey: string;
}

export interface Settings extends Character {
  avatars: { key: string; avatarUrl: string }[];
  musics: SettingsMusic[];
  tokens: GameToken[];
  cards: GameCard[];
  decks: GameDeck[];
  dices: GameDice[];
}

export type GameLayoutPanelType = "character" | "chat" | "table" | "story";
export interface GameLayoutPanel {
  hidden: boolean;
  panels?: GameLayoutPanelType[];
}

export interface GameLayout {
  left: GameLayoutPanel;
  middle: GameLayoutPanel;
  right: GameLayoutPanel;
  drawer: GameLayoutPanel;
}

export interface GameRoot {
  maps: Map[];
  sections: Character;
  internal: Character;
  showUserChat: boolean;
  showChapters: boolean;
  playerEditSelf: boolean;
  overrideAvatars: boolean;
  overrideMusic: boolean;
  hidePlayerActions: boolean;
  layout: GameLayout;
  settings: Settings;
  charactersNames: string[];
  gmNote: string;
}

export interface DefaultGame {
  type: GameType.Default;
  me: Character;
  url: null | string;
  name: string;
  gameMasterId: number;
  id: number;
  messages: UserTextMessage[];
  sections: Section[];
  characters: Record<number, Character>;
  root: GameRoot;
  userOnlineIds: number[];
}

export type Primitive = number | boolean | string | null;
export type PrimitiveObject = Record<
  string,
  undefined | Primitive | Record<string, any>
>;

export function isPrimitive(input: any): input is Primitive {
  const vType = typeof input;
  return vType === "string" || vType === "number" || vType === "boolean";
}

export interface CharacterTabPanelBase extends PrimitiveObject {
  key: string;
  title: string | null;
  __path?: string;
  visible?: boolean;
  __contextName?: boolean;
  __panels: CharacterTabPanel[] | null;
}
export interface CharacterJustTabPanel extends CharacterTabPanelBase {
  __type: "tab" | null;
}

export interface CharacterCardPanel extends CharacterTabPanelBase {
  __type: "card" | null;
  cards: CardFlyweight[];
}

export interface CharacterTablePanel extends CharacterTabPanelBase {
  __type: "table" | null;
  __columns: { key: string; title: string }[];
  __data: Record<string, PrimitiveObject>[];
}

export interface CharacterGenericPanel extends CharacterTabPanelBase {
  title: string | null;
  __type: "generic" | null;
}

export interface CharacterTextPanel extends CharacterTabPanelBase {
  title: string | null;
  richText: string;
  __type: "text" | "image" | null;
}

export type CharacterTabPanel =
  | CharacterTextPanel
  | CharacterGenericPanel
  | CharacterTablePanel
  | CharacterJustTabPanel
  | CharacterCardPanel;

export interface IteractionConditionNode {
  path: string;
  type?: string;
}

export interface InteractionCondtion {
  left: IteractionConditionNode;
  right: IteractionConditionNode;
  operand: "greater" | "less";
}

export interface InteractionEffect {
  node: IteractionConditionNode;
  operand: "increase" | "decrease" | "replace";
  value: number;
  condition: "positiveResult" | "none";
}

export interface Interaction {
  // Edit is from input - execute is from click
  key: string;
  type?: "execute" | "edit";
  node?: IteractionConditionNode;
  desc: string;
  cost: string;
  conditions?: InteractionCondtion[];
  effects: InteractionEffect[];
}

export const SECTION_ADMIN_KEY = -1001;
export const SETTINGS_ADMIN_KEY = -1002;
export const INTERNAL_ADMIN_KEY = -1003;

export const makeSections = (internal: Character) => {
  return {
    ...internal,
    key: SECTION_ADMIN_KEY,
    name: i18next.t("GAME.STORY_RIGHT"),
  } as Character;
};

export const makeInternal = (internal: Character) => {
  return {
    ...internal,
    key: INTERNAL_ADMIN_KEY,
    name: i18next.t("GAME.HANDY"),
  } as Character;
};

export const mapIdToPath = (id: number) => {
  switch (id) {
    case SECTION_ADMIN_KEY:
      return "sections";
    case SETTINGS_ADMIN_KEY:
      return "settings";
    case INTERNAL_ADMIN_KEY:
      return "internal";
    default:
      return "";
  }
};

export interface Character extends PrimitiveObject {
  key: number;
  name: string;
  __userId: number;
  __panels: CharacterTabPanel[] | null;
  __tabs: CharacterTabPanel[] | null;
  __avatarUrl: string | null;
  __note: string | null;
  __interactions: Interaction[] | null;
}

export interface Section {
  id: number;
  richText: string;
}

// export interface Character {
//   inventory: null | Inventory;
//   items: { [key: string]: null | Item } | null;
//   lastName: null | string;
//   name: null | string;
//   properties: { [key: string]: null | Property } | null;
// }

// export interface Inventory {
//   armors: { [key: string]: null | Armor } | null;
//   weapons: { [key: string]: null | Weapon } | null;
// }

// export interface Armor {
//   defense: number;
//   description: null | string;
//   key: null | string;
//   location: null | string;
//   name: null | string;
//   quantity: number;
//   type: null | string;
//   unit: null | string;
//   weight: number;
// }

// export interface Weapon {
//   description: null | string;
//   key: null | string;
//   maxDamage: number;
//   minDamage: number;
//   modifier: number;
//   name: null | string;
//   quantity: number;
//   unit: null | string;
//   weight: number;
// }

// export interface Item {
//   description: null | string;
//   key: null | string;
//   name: null | string;
//   quantity: number;
//   unit: null | string;
//   weight: number;
// }

// export interface Property {
//   begin: number;
//   category: number;
//   description: null | string;
//   key: null | string;
//   modifier: number;
//   name: null | string;
//   shortName: null | string;
//   tempModifier: number;
// }
