import "./DeckPlace.css";
import { MapCardPlace } from "../../../model/Map";
import { clsx } from "../../../shared/utils/clsx";
import { useGame } from "../../character/hooks/useGame";
import { useMapDrag } from "../../dices/use-map-drag";
import { dev } from "../../../shared/utils/log";

export interface DeckPlaceProps {
  fixed?: boolean;
  deck: MapCardPlace;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}
export const DeckPlace = (props: DeckPlaceProps) => {
  const { deck, onClick, fixed = false } = props;

  const handlers = useMapDrag({
    draggableDb: "deckPlaces",
    key: deck.key,
    position: deck.position,
    fixed,
  });

  const { game } = useGame();
  const originalDeck = game.root.settings.decks.find(
    (x) => x.key === deck.deckKey || x.key === deck.card?.deckKey,
  );

  const avatarUrl =
    deck.type === "card"
      ? deck.card?.avatarUrl
      : originalDeck?.reverseAvatarUrl;

  const uiType =
    deck.type === "card" ? deck.card?.uiType : originalDeck?.uiType;
  const color =
    deck.type === "card"
      ? deck.card?.textColor || originalDeck?.cardTextColor
      : originalDeck?.textColor;
  const backgroundColor =
    deck.type === "card"
      ? deck.card?.color || originalDeck?.cardColor
      : originalDeck?.color;

  dev.log([deck.card, deck]);
  return (
    <div
      {...handlers}
      style={{
        backgroundColor: backgroundColor || "black",
        color: color || "white",
        backgroundImage: `url(${avatarUrl})`,
        backgroundSize: "cover",
        rotate: `${deck.rotate || 0}deg`,
        ...handlers.style,
      }}
      className={clsx("deck-place", "disable-map", "no-drag", {
        [`uiType-big-${uiType}`]: !!uiType,
      })}
      onClick={onClick}
    >
      {deck.card?.name
        ? avatarUrl
          ? ""
          : deck.card.name
        : `${originalDeck?.name} [${deck?.cards.length}]`}
    </div>
  );
};
