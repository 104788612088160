import { useState, useCallback } from "react";
import { CreateGameModal } from "./modal/CreateGameModal";
import React from "react";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";

export const GameListActions = () => {
  const [modal, setModal] = useState("");
  const { t } = useTranslation();
  const handleClose = useCallback(() => setModal(""), []);

  return (
    <React.Fragment>
      <Button
        sx={{
          height: "100%",
        }}
        variant="outlined"
        onClick={() => setModal("create")}
        startIcon={<AddIcon />}
        size="large"
      >
        {t("GAME.CREATE")}
      </Button>
      <CreateGameModal open={modal === "create"} handleClose={handleClose} />
    </React.Fragment>
  );
};
