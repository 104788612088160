import { Box, TextField } from "@mui/material";
import { useEffect } from "react";
import { useGame } from "../../character/hooks/useGame";
import { usePatchGame } from "../hooks/usePatchGame";
import React from "react";
import { useForm } from "react-hook-form";
import { updateBodyBackground } from "../../../shared/hooks/useBackgoundImage";
import { useTranslation } from "react-i18next";
import UploadButton from "../../../shared/components/logic/UploadButton";

export interface BasicDataUpdateState {
  url: string | null;
  name: string;
}

export const BasicDataUpdate = () => {
  const { game } = useGame();
  const { t } = useTranslation();

  const { register, getValues, reset, setValue } =
    useForm<BasicDataUpdateState>({
      defaultValues: {
        url: game.url,
        name: game.name,
      },
    });

  useEffect(() => {
    reset({
      url: game.url,
      name: game.name,
    });
  }, [game, reset]);

  const { mutate } = usePatchGame();

  const save = () => {
    const values = getValues();
    const url = values.url?.trim() || " ";
    updateBodyBackground(values.url?.trim());
    mutate({ id: game.id, ...values, url: url });
  };

  return (
    <React.Fragment>
      <TextField
        label={t("GAME.GAME_NAME")}
        {...register("name")}
        onBlur={save}
      />
      <Box display="grid" gridTemplateColumns="3fr 1fr" gap="0.5rem"> 
        <TextField
          label={t("GAME.GAME_WALLPAPER_URL")}
          {...register("url")}
          onBlur={save}
        />
        <UploadButton
          label={t("COMMON.UPLOAD")}
          onUpload={(key, value) => {
            setValue("url", value);
            save();
          }}
        />
      </Box>
    </React.Fragment>
  );
};
