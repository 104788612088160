import { IconButton, TextField, Tooltip } from "@mui/material";
import {
  GameToken,
  GameTokenConnection,
  SETTINGS_ADMIN_KEY,
} from "../../model/Game";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import { useMemo, useState } from "react";
import { TokenConnectModal } from "./components/TokenConnectModal";
import { useTranslation } from "react-i18next";
import { useGame } from "../character/hooks/useGame";
import { Table } from "../../shared/components/ui/Table";
import { mapGameDbAsPanel, useGameDbPanels } from "./hooks/useGameDbPanels";
import { useGameDb, useGameDbById } from "./hooks/useGameDb";
import PreviewIcon from "@mui/icons-material/Preview";
import { Popover } from "../../shared/components/ui/popover/Popover";
import { GenericPanel } from "../character/components/generic/GenericPanelView";
import { CharacterTabProvider } from "../character/components/generic/CharacterTabProvider";
import { QuickEditModalProvider } from "../quick-edit/hooks/useQuickEdit";
import { cuni } from "../../shared/services/cuni/cuni";
import { useForm } from "react-hook-form";

interface TokenDetailEditViewProps {
  token: GameToken;
  tokenIndex: number;
}

interface TokenForm {
  name: string;
  avatarUrl: string;
}

export const TokenDetailEditView = (props: TokenDetailEditViewProps) => {
  const { token, tokenIndex } = props;
  const [modal, setModal] = useState("");

  const { t } = useTranslation();

  const { game } = useGame();

  const { rows, headers } = useMemo(() => {
    const items = token.connections || [];
    const headers = [
      {
        key: "dbKey",
        title: t("COMMON.DATABASE"),
        cell: DatabaseNameCell,
      },
      {
        key: "panelKey",
        title: t("COMMON.PANEL"),
        cell: PanelNameCell,
      },
    ];
    const rows = items.map((x) => ({
      ...x,
    }));
    return { rows, headers };
  }, [token.connections, t]);

  const { register } = useForm<TokenForm>({
    defaultValues: token,
  });

  const updateToken = (updater: (t: GameToken) => void) => {
    const tokens = game.root.settings?.tokens ?? [];
    const dbToken = tokens.find((x) => x.key === token.key);
    if (!dbToken) return;
    updater(dbToken);
    cuni.update(SETTINGS_ADMIN_KEY, "tokens", tokens);
  };
  return (
    <div className="column">
      <div className=" paper-outline">
        <Tooltip title={t("TOKEN.CONNECT")}>
          <IconButton onClick={() => setModal("connect")}>
            <BookmarkAddedIcon />
          </IconButton>
        </Tooltip>
        <Popover
          renderButton={(onClick) => (
            <Tooltip title={t("TOKEN.PREVIEW")}>
              <IconButton onClick={onClick}>
                <PreviewIcon />
              </IconButton>
            </Tooltip>
          )}
        >
          <TokenView token={token} />
        </Popover>
      </div>

      <TextField
        label={t("TOKEN.NAME")}
        {...register("name")}
        onBlur={(e) => {
          updateToken((t) => (t.name = e.currentTarget.value));
        }}
      />
      <TextField
        label={t("TOKEN.AVATAR")}
        {...register("avatarUrl")}
        onBlur={(e) => {
          updateToken((t) => (t.avatarUrl = e.currentTarget.value));
        }}
      />
      <Table
        titleSufix={`tokens.${tokenIndex}.connections`}
        directList
        root={{ ...game.root.settings, key: SETTINGS_ADMIN_KEY }}
        headers={headers}
        className="paper-outline"
        rows={rows}
        disableGenericMenu
      />

      <TokenConnectModal
        tokenId={token.key}
        open={modal === "connect"}
        handleClose={() => setModal("")}
      />
    </div>
  );
};

export const DatabaseNameCell = (props: GameTokenConnection) => {
  const database = useGameDbById(props.dbKey);
  return <div>{database?.name}</div>;
};

export const PanelNameCell = (props: GameTokenConnection) => {
  const database = useGameDbById(props.dbKey);
  const panels = useGameDbPanels(database);
  const panel = panels.find((x) => x.key === props.panelKey);
  if (!panel) return <div></div>;
  const nameKey: string = "name";
  const name = panel[nameKey] as string;
  return <div>{panel.title || name}</div>;
};

interface TokenViewProps {
  token: GameToken;
}
export const TokenView = (props: TokenViewProps) => {
  const { token } = props;
  const { list: database } = useGameDb();
  const view = token.connections.map((con) => {
    const db = database.find((db) => db.key === con.dbKey);
    if (!db) throw new Error("There is no db");
    const panels = mapGameDbAsPanel(db);
    const panel = panels.find((x) => x.key === con.panelKey);
    if (!panel) throw new Error("There is no panel");
    if (panel.__path === undefined) throw new Error("Panel need to have path");

    // TODO:
    // error during render (more hooks something...)
    // view even looks good, but paths are messed up
    return (
      <CharacterTabProvider
        skipTab
        key={`${con.dbKey}_${con.panelKey}`}
        character={db}
      >
        <QuickEditModalProvider character={db}>
          <GenericPanel
            className={"paper-outline"}
            panel={panel}
            path={panel?.__path}
          />
        </QuickEditModalProvider>
      </CharacterTabProvider>
    );
  });

  return <>{view}</>;
};
