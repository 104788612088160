import React, { useState } from "react";
import { BasicView } from "../shared/components/ui/BasicView";
import { useAuth } from "../features/auth/useAuth";
import { useNavigate } from "react-router-dom";
import { Alert, Box, Button, Link, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { Conditional } from "../shared/components/logic/Conditional";
import { LoadingButton } from "../shared/components/ui/LoadingButton";
import { useTranslation } from "react-i18next";

interface LoginPageForm {
  login: string;
  password: string;
}

export const LoginPage: React.FC = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<LoginPageForm>();
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState<string>();
  const submit = async ({ login, password }: LoginPageForm) => {
    try {
      setLoading(true);
      const { needCodeValidaton } = await auth.login(login, password);
      if (needCodeValidaton) navigate("/confirm");
      else navigate("/list");
    } catch (error) {
      setApiError(
        // @ts-ignore
        error.response.status === 401
          ? t("ERROR.WRONG_CREDENTIALS")
          : t("ERROR.TRY_AGAIN_LATER"),
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <BasicView>
        <label>{t("LOGIN.SIGN_IN")}</label>
        <TextField
          label={t("LOGIN.USERNAME_OR_EMAIL")}
          {...register("login", { required: true })}
          error={Boolean(errors.login)}
        />
        <TextField
          label={t("LOGIN.PASWORD")}
          type="password"
          {...register("password", { minLength: 3, required: true })}
          error={Boolean(errors.password)}
        />
        <Conditional condition={apiError}>
          <Alert severity="error">{apiError}</Alert>
        </Conditional>
        <LoadingButton
          loading={loading}
          variant="contained"
          type="submit"
          disabled={!isValid}
        >
          {t("LOGIN.LOGIN")}
        </LoadingButton>
        <Button onClick={() => navigate("/register")}>
          {t("LOGIN.REGISTER")}
        </Button>
        <Box display="flex" justifyContent="center">
          <Link
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              navigate("/forgot-password");
            }}
          >
            {`${t("LOGIN.FORGOT_PASSWORD")} ?`}
          </Link>
        </Box>
      </BasicView>
    </form>
  );
};
