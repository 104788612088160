import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { IconButton, Tooltip } from "@mui/material";
import { PropsWithChildren, useState } from "react";
import { Conditional } from "../../../../../../shared/components/logic/Conditional";
import { useQuickEdit } from "../../../../../quick-edit/hooks/useQuickEdit";
import { cuni } from "../../../../../../shared/services/cuni/cuni";
import { useCharacterTabs } from "../../CharacterTabProvider";
import { NewPropertyModal } from "./components/NewPropertyModal";
import { getTabOrPanelIdentifier } from "../../../../../../model/utils/charaterUtils";
import { NewRowModal } from "./components/NewRowModal";
import EditIcon from "@mui/icons-material/Edit";
import { AreYouSureModal } from "../../../../../../shared/components/modal/AreYouSureModal";
import { useGamePermission } from "../../../../../../shared/hooks/useGamePermission";
import { useGameEdit } from "../../../../../schema/hooks/useGameEdit";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { file } from "../../../../../../shared/utils/file";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import { useTranslation } from "react-i18next";
import CommentBankIcon from "@mui/icons-material/CommentBank";
import { CharacterTabPanel } from "../../../../../../model/Game";
import {
  cutLastNParts,
  getLastPart,
  getPropertyByPath,
} from "../../../../../quick-edit/flattenDict";

type PanelMenuAction =
  | "ADD_PROPERTY"
  | "REMOVE_PANEL"
  | "ADD_ROW"
  | "EDIT_CONTENT"
  | "EXPORT"
  | "TABLE"
  | "CONTEXT_NAME";

interface PanelMenuProps {
  actions: PanelMenuAction[];
  path: string;
}

export const PanelMenu = (props: PropsWithChildren<PanelMenuProps>) => {
  const { children, actions, path } = props;
  const { hasGamePermission } = useGamePermission();

  const { t } = useTranslation();

  const [modal, setModal] = useState("");
  const { edit } = useQuickEdit();
  const { tab, tabId, character, skipTab } = useCharacterTabs();
  const { generic } = useGameEdit();

  if (!hasGamePermission(generic ? "canEditGame" : "canEditCharacter"))
    return <>{children}</>;

  let currentPanel = null;
  let panels: CharacterTabPanel[] = [];
  let index = 0;
  try {
    // TODO: if tabId !== tabId from path - throw error to fix it!
    // I think skipTab is not needed here
    if (skipTab) {
      const idxString = getLastPart(path);
      index = parseInt(idxString);
      const panelsPath = cutLastNParts(path, 1);
      panels = getPropertyByPath(character, panelsPath);
    } else {
      index = getTabOrPanelIdentifier(path);
      panels = tab.__panels ?? [];
    }
    currentPanel = panels[index];
  } catch (error) {
    currentPanel = null;
  }

  const removePanel = () => {
    panels.splice(index, 1);
    const updatePath =
      tab.title === "main" ? "__panels" : `__tabs.${tabId - 1}.__panels`;
    cuni.update(character.key, updatePath, panels);
  };

  const exportPanel = () => {
    const object = panels[index];
    file.export({ object, name: object.title || "empty" });
  };

  const exportGeneric = () => {
    const object = character[path];
    if (!object) throw new Error(`${path} not exist on ${character}`);
    file.export({ object, name: path.replace(".", "_") });
  };

  const genericReplace = () => {
    document.getElementById(`fileInput-${path}`)?.click();
  };

  const genericReplaceFromFile = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const result = await file.import(event);
    const object = character[path];
    if (!object) throw new Error(`${path} not exist on ${character}`);
    cuni.update(character.key, path, result);
  };

  const changePanelContext = () => {
    panels[index].__contextName = !panels[index].__contextName;
    const updatePath =
      tab.title === "main" ? "__panels" : `__tabs.${tabId - 1}.__panels`;
    cuni.update(character.key, updatePath, panels);
  };

  return (
    <div className="paper__menu--handler">
      <div className="paper__menu">
        <Conditional
          condition={
            actions.includes("CONTEXT_NAME") && hasGamePermission("canEditGame")
          }
        >
          <Tooltip title={t("GAME.CONTEXT_NAME")}>
            <IconButton
              color={currentPanel?.__contextName ? "warning" : undefined}
              size="small"
              onClick={changePanelContext}
            >
              <CommentBankIcon />
            </IconButton>
          </Tooltip>
        </Conditional>
        <Conditional condition={actions.includes("ADD_PROPERTY")}>
          <Tooltip title={t("GAME.ADD_PROPERTY")}>
            <IconButton size="small" onClick={() => setModal("ADD_PROPERTY")}>
              <AddCircleIcon />
            </IconButton>
          </Tooltip>
        </Conditional>
        <Conditional condition={actions.includes("ADD_ROW")}>
          <Tooltip title={t("GAME.ADD_ROW")}>
            <IconButton size="small" onClick={() => setModal("ADD_ROW")}>
              <PlaylistAddIcon />
            </IconButton>
          </Tooltip>
        </Conditional>
        <Conditional condition={actions.includes("EDIT_CONTENT") && path}>
          <Tooltip title={t("GAME.EDIT_CONTENT")}>
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                edit({
                  path: `${path}.richText`,
                  value: "",
                });
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Conditional>
        <Conditional
          condition={
            actions.includes("EXPORT") &&
            path &&
            hasGamePermission("canEditGame")
          }
        >
          <Tooltip title={t("GAME.EXPORT_PANEL")}>
            <IconButton size="small" onClick={exportPanel}>
              <FileDownloadIcon />
            </IconButton>
          </Tooltip>
        </Conditional>

        <Conditional
          condition={
            actions.includes("TABLE") &&
            path &&
            hasGamePermission("canEditGame")
          }
        >
          <>
            <Tooltip title={t("GAME.REPLACE_TABLE")}>
              <IconButton size="small" onClick={genericReplace}>
                <input
                  type="file"
                  id={`fileInput-${path}`}
                  style={{ display: "none" }}
                  accept="application/json"
                  onChange={genericReplaceFromFile}
                />
                <SyncAltIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("GAME.EXPORT_TABLE")}>
              <IconButton size="small" onClick={exportGeneric}>
                <FileDownloadIcon />
              </IconButton>
            </Tooltip>
          </>
        </Conditional>
        <Conditional
          condition={
            actions.includes("REMOVE_PANEL") &&
            path &&
            hasGamePermission("canEditGame")
          }
        >
          <Tooltip title={t("GAME.REMOVE_PANEL")}>
            <IconButton
              size="small"
              color="error"
              onClick={() => setModal("REMOVE_PANEL")}
            >
              <CancelIcon />
            </IconButton>
          </Tooltip>
        </Conditional>
      </div>
      <NewPropertyModal
        open={modal === "ADD_PROPERTY"}
        handleClose={() => setModal("")}
        path={path}
      />
      <NewRowModal
        open={modal === "ADD_ROW"}
        handleClose={() => setModal("")}
        path={path}
      />
      <AreYouSureModal
        title={t("GAME.DELETE_PANEL")}
        content={t("GAME.DELETE_PANEL_QUESTION")}
        handleClose={() => setModal("")}
        confirmText={t("COMMON.DELETE")}
        confirmAction={removePanel}
        open={modal === "REMOVE_PANEL"}
      />
      {children}
    </div>
  );
};
