import { Tree } from "../../../features/character/components/generic/GenericTemplateView";
import {
  QuickEditState,
  QuickEditType,
  useQuickEdit,
} from "../../../features/quick-edit/hooks/useQuickEdit";
import { useGamePermission } from "../../hooks/useGamePermission";
import "./Table.css";
import { useGameEdit } from "../../../features/schema/hooks/useGameEdit";
import { flattenDict } from "../../../features/quick-edit/flattenDict";
import { RowMenu, RowMenuProps } from "./row-menu/RowMenu";
import { GenericPanelMenu } from "../../../features/character/components/generic/components/panel-menu/GenericPanelMenu";
import { clsx } from "../../utils/clsx";

export interface TableProps {
  headers: {
    key: string;
    title: string;
    cell?: (item: any, index: number) => JSX.Element | string;
    type?: QuickEditType;
  }[];
  rows: Record<string, any>[];
  title?: string | null;
  titleSufix?: string;
  disableGenericMenu?: boolean;
  directList?: boolean;
  root?: any;
  className?: string;
  onRowRemove?: (value: any) => void;
}

export const Table = (props: TableProps) => {
  const {
    headers,
    rows,
    title,
    titleSufix,
    directList,
    root,
    className,
    onRowRemove,
    disableGenericMenu = false,
  } = props;
  const dataTable = directList ? "" : "__data.";

  const gridTemplateColumns = headers.reduce((p, c) => `${p} 1fr`, "");

  const component = (
    <div className={clsx("paper", className)}>
      {title && titleSufix && (
        <Tree justValue obj={{ title }} sufix={titleSufix} />
      )}
      <div className="table__container" style={{ gridTemplateColumns }}>
        {headers.map((x) => (
          <div key={`key_${x.key}`} className="table__headerCell">
            {x.title}
          </div>
        ))}

        {rows.map((item, idx) => (
          <RowMenu
            key={`row_${idx}`}
            style={{ gridTemplateColumns }}
            tablePath={`${titleSufix}.${dataTable}`}
            rowId={idx}
            onRowRemove={onRowRemove ? () => onRowRemove(item) : undefined}
          >
            {Object.entries(item)
              .filter((y) => {
                const col = headers.find((x) => x.key === y[0]);
                // extend by col
                if (typeof y[1] === "object") {
                  const flatten = flattenDict(y[1]);
                  return Object.keys(flatten).some((key) =>
                    headers.some((header) => header.key === `${y[0]}.${key}`),
                  );
                }
                return col;
              })
              .map((y) => {
                let col = headers.find((x) => x.key === y[0]);
                let value = y[1];
                if (typeof y[1] === "object") {
                  const flatten = flattenDict(y[1]) as Record<string, any>;
                  col = headers.find((header) =>
                    Object.keys(flatten).some(
                      (key) => header.key === `${y[0]}.${key}`,
                    ),
                  );
                  const key = col?.key.replace(`${y[0]}.`, "");
                  if (key) value = flatten[key];
                }

                if (col?.cell) return col.cell(item, idx);

                const path = `${titleSufix}.${dataTable}${idx}.${col?.key}`;
                return (
                  <TableCell
                    label={col?.title}
                    key={path}
                    path={path}
                    value={value}
                    type={col?.type}
                  />
                );
              })}
          </RowMenu>
        ))}
      </div>
    </div>
  );

  if (root && titleSufix && !disableGenericMenu)
    return (
      <GenericPanelMenu root={root} path={titleSufix}>
        {component}
      </GenericPanelMenu>
    );

  return component;
};

interface TableCellProps extends QuickEditState {}

const TableCell = (props: TableCellProps) => {
  const { value } = props;
  const { hasGamePermission } = useGamePermission();
  const { generic } = useGameEdit();
  const { getProps } = useQuickEdit();
  const { actionComponent, ...moreProps } = (
    generic
      ? hasGamePermission("canEditGame")
      : hasGamePermission("canEditCharacter")
  )
    ? getProps(props)
    : { actionComponent: null, className: "" };

  return (
    <div {...moreProps} className={"table__cell " + moreProps.className}>
      {actionComponent}
      {value}
    </div>
  );
};
