import { Box, Button, TextField, Typography } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { config } from "../../config";
import { Invitation } from "../../model/Invitation";
import { apiClient } from "../../shared/services/apiClient";
import { FormValidation } from "../../shared/services/validator/FormValidation";
import { useTranslation } from "react-i18next";

export const InvitationSender = () => {
  const queryClient = useQueryClient();
  const { mutate } = usePostInvitation();
  const { t } = useTranslation();

  const [email, setEmail] = useState<string>();
  const isValid = FormValidation.validateEmail(email || "");
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        mutate(
          {
            email,
          },
          {
            onSuccess: () => {
              queryClient.invalidateQueries({
                queryKey: ["friend"],
                exact: true,
              });
            },
          },
        );
        setEmail("");
      }}
    >
      <Box
        className="paper"
        display="flex"
        flexDirection="column"
        gap="0.25rem"
      >
        <Typography id="modal-avatar-description" sx={{ mt: 2 }}>
          {t("PROFILE.INVITE_INPUT")}
        </Typography>
        <TextField
          label={t("LOGIN.EMAIL")}
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button type="submit" disabled={!isValid}>
          {t("COMMON.SEND")}
        </Button>
      </Box>
    </form>
  );
};

const usePostInvitation = () => {
  return useMutation({
    mutationFn: (data: Partial<Invitation>) =>
      apiClient.post<Invitation>(`${config.baseUrl}/api/invitation`, data),
  });
};
