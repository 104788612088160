import { useGame } from "../../hooks/useGame";
import "./Avatar.css";
import { AvatarImage } from "./AvatarImage";

export const AvatarView = () => {
  const { game } = useGame();
  if (!game.characters) return null;
  const userOnlineIds = game.userOnlineIds ?? [];
  const components = Object.entries(game.characters)
    .map((x) => x[1])
    .map((character, idx) => {
      return (
        <div
          key={character.key}
          className="avatar__image--container"
          style={{
            right: 32 * (Object.entries(game.characters).length - idx),
          }}
        >
          <AvatarImage
            online={userOnlineIds.includes(character.__userId)}
            imgUrl={character.__avatarUrl || ""}
          />
        </div>
      );
    });
  return <div className="avatar__container">{components}</div>;
};
