import { useEffect, useRef, useState } from "react";
import { cuni } from "../../shared/services/cuni/cuni";
import { useMaps } from "../map-hex/components/MapProvider";
import { GamePosition } from "../../model/Game";
import { Map } from "../../model/Map";

interface UseMapDragProps {
  position?: GamePosition;
  key: string;
  draggableDb: "tokens" | "deckPlaces" | "dices";
  fixed?: boolean;
  classNameSelector?: string;
}

export const useMapDrag = (props: UseMapDragProps) => {
  const {
    position: startingPosition,
    key,
    draggableDb,
    fixed,
    classNameSelector,
  } = props;
  const [position, setPosition] = useState(
    startingPosition ?? { x: 0, y: 0, zIndex: 200 },
  );
  useEffect(() => {
    if (
      startingPosition &&
      startingPosition.x !== position.x &&
      startingPosition.y !== position.y
    )
      setPosition(startingPosition);
  }, [startingPosition, startingPosition?.x, startingPosition?.y]);

  const isDraggingRef = useRef(false);
  const offset = useRef({ x: 0, y: 0 });

  const targetRef = useRef<HTMLElement | null>(null);

  const getScaleFromMapContainer = (): number | null => {
    const mapContainer =
      document.querySelector(".map__container")?.parentElement;
    if (mapContainer) {
      const transform = mapContainer.style.transform;
      const match = transform.match(/scale\(([^)]+)\)/);
      if (match && match[1]) {
        return parseFloat(match[1]);
      }
    }
    return 1; // Default to scale 1 if not found
  };
  // @ts-ignore
  const onMouseDown = (e) => {
    targetRef.current = classNameSelector
      ? findParentWithClassName(e.target, classNameSelector)
      : e.target;

    isDraggingRef.current = true;
    const scale = getScaleFromMapContainer() ?? 1;
    offset.current = {
      x: e.clientX / scale - position.x,
      y: e.clientY / scale - position.y,
    };
    document.addEventListener("mousemove", onMouseMoveWindow, {
      passive: false,
    });
    document.addEventListener("mouseup", onMouseUpWindow, { passive: false });
  };

  // @ts-ignore
  const onMouseMoveWindow = (e) => {
    if (isDraggingRef.current) {
      targetRef.current!.style.pointerEvents = "none";
      const scale = getScaleFromMapContainer() ?? 1;
      setPosition({
        x: e.clientX / scale - offset.current.x,
        y: e.clientY / scale - offset.current.y,
      });
    }
  };

  const { all, current } = useMaps();

  const positionRef = useRef<typeof position>();
  useEffect(() => {
    positionRef.current = position;
  }, [position]);
  // @ts-ignore
  const onMouseUpWindow = (e) => {
    isDraggingRef.current = false;
    offset.current = { x: 0, y: 0 };
    targetRef.current!.style.pointerEvents = "unset";

    document.removeEventListener("mousemove", onMouseMoveWindow);
    document.removeEventListener("mouseup", onMouseUpWindow);

    if (!current) return;
    const dbToken = (current[draggableDb] as any[]).find(
      (x: any) => x.key === key,
    );
    const maxZIndex = getTopIndex(current);
    if (dbToken && positionRef.current) {
      dbToken.position = positionRef.current;
      positionRef.current.zIndex = maxZIndex + 1;
      dbToken.position.zIndex = maxZIndex + 1;
    }
    cuni.updateGame("maps", all);
  };

  const style: React.CSSProperties = fixed
    ? {}
    : {
        position: !fixed ? "absolute" : "unset",
        left: `${position.x}px`,
        top: `${position.y}px`,
        cursor: fixed ? "pointer" : "grab",
        zIndex: isDraggingRef.current ? 5000 : positionRef.current?.zIndex,
      };
  return {
    onMouseDown,
    style,
  };
};

function findParentWithClassName(
  target: EventTarget | null,
  className: string,
): HTMLElement | null {
  let element = target as HTMLElement;

  // @ts-ignore
  while (element && element !== document) {
    if (element.classList && element.classList.contains(className)) {
      return element;
    }
    element = element.parentElement as HTMLElement;
  }

  return null;
}

export const getTopIndex = (current: Map) => {
  const maxZIndex = [
    ...current.tokens,
    ...current.deckPlaces,
    ...current.dices,
  ].reduce((p, c) => {
    const zIndex = c.position?.zIndex || 200;
    return p > zIndex ? p : zIndex;
  }, 200);
  return maxZIndex;
};
