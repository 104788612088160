import React from "react";

import { CharacterTabPanel } from "../../../../model/Game";
import { GenericTemplateView, Tree } from "./GenericTemplateView";
import { Parser } from "html-to-react";
import { TablePanelView } from "./components/table-panel-view/TablePanelView";
import { PanelMenu } from "./components/panel-menu/PanelMenu";
import { clsx } from "../../../../shared/utils/clsx";
import { CardPanelView } from "./components/CardPanelView";

export interface GenericPanelViewProps {
  path: string;
  panels: CharacterTabPanel[];
}

export const GenericPanelView = (props: GenericPanelViewProps) => {
  const { panels, path } = props;

  const components = panels.map((temp, idx) => {
    return <GenericPanel panel={temp} path={`${path}.${idx}`} />;
  });
  return <React.Fragment>{components}</React.Fragment>;
};

interface GenericPanelProps {
  panel: CharacterTabPanel;
  path: string;
  className?: string;
}

export const GenericPanel = (props: GenericPanelProps) => {
  const { panel: temp, path, className } = props;
  switch (temp.__type) {
    case "image":
      return (
        <PanelMenu
          key={temp.title}
          actions={["REMOVE_PANEL", "EXPORT", "EDIT_CONTENT"]}
          path={path}
        >
          <div className={clsx("paper", className)}>
            <img
              alt="custom"
              style={{ maxWidth: 200, maxHeight: 200 }}
              src={temp.richText}
            />
          </div>
        </PanelMenu>
      );
    case "text":
      return (
        <PanelMenu
          key={temp.title}
          actions={["REMOVE_PANEL", "EXPORT", "EDIT_CONTENT"]}
          path={path}
        >
          <div className={clsx("paper", className)}>
            <Tree justValue obj={{ title: temp.title }} sufix={path} />
            {Parser().parse(temp.richText)}
          </div>
        </PanelMenu>
      );
    case "card":
      return (
        <PanelMenu
          key={temp.title}
          actions={["REMOVE_PANEL", "EXPORT"]}
          path={path}
        >
          <div className={clsx("paper", className)}>
            <Tree justValue obj={{ title: temp.title }} sufix={path} />
            <CardPanelView panel={temp} path={path} />
          </div>
        </PanelMenu>
      );
    case "table":
      return (
        <TablePanelView
          key={temp.title}
          panel={temp}
          path={path}
          className={className}
        />
      );
    case "generic":
    default:
      return (
        <GenericTemplateView
          key={temp.title}
          template={temp}
          path={path}
          className={className}
        />
      );
  }
};
