import { IconButton, Tooltip } from "@mui/material";
import { PropsWithChildren } from "react";
import { cuni } from "../../../../../../shared/services/cuni/cuni";
import { useGamePermission } from "../../../../../../shared/hooks/useGamePermission";
import { useGameEdit } from "../../../../../schema/hooks/useGameEdit";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { file } from "../../../../../../shared/utils/file";
import SyncAltIcon from "@mui/icons-material/SyncAlt";

interface PanelMenuProps {
  path: string;
  root: any;
}
/**
 * Generic version of PanelMenu for tables.
 */
export const GenericPanelMenu = (props: PropsWithChildren<PanelMenuProps>) => {
  const { children, path, root } = props;
  const { hasGamePermission } = useGamePermission();

  const { generic } = useGameEdit();

  if (!hasGamePermission(generic ? "canEditGame" : "canEditCharacter"))
    return <>{children}</>;

  const exportGeneric = () => {
    const object = root[path];
    if (!object) throw new Error(`${path} not exist on ${root}`);
    file.export({ object, name: path.replace(".", "_") });
  };

  const genericReplace = () => {
    document.getElementById(`fileInput-${path}`)?.click();
  };

  const genericReplaceFromFile = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const result = await file.import(event);
    const object = root[path];
    if (!object) throw new Error(`${path} not exist on ${root}`);
    cuni.update(root.key, path, result);
  };

  return (
    <div className="paper__menu--handler">
      <div className="paper__menu">
        <Tooltip title="Replace table">
          <IconButton size="small" onClick={genericReplace}>
            <input
              type="file"
              id={`fileInput-${path}`}
              style={{ display: "none" }}
              accept="application/json"
              onChange={genericReplaceFromFile}
            />
            <SyncAltIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Export table">
          <IconButton size="small" onClick={exportGeneric}>
            <FileDownloadIcon />
          </IconButton>
        </Tooltip>
      </div>
      {children}
    </div>
  );
};
