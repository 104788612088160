import { Box, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import { GameDice, SETTINGS_ADMIN_KEY } from "../../model/Game";
import { useTranslation } from "react-i18next";
import { useGame } from "../character/hooks/useGame";
import PreviewIcon from "@mui/icons-material/Preview";
import { Popover } from "../../shared/components/ui/popover/Popover";
import { cuni } from "../../shared/services/cuni/cuni";
import { FormProvider, useForm } from "react-hook-form";
import { ColorPickerField } from "../chat/components/color-picker-field/ColorPickerField";
import { ColorPicker } from "../../shared/components/ui/color-picker/ColorPicker";
import { DiceView } from "./DiceView";

interface CardDetailEditViewProps {
  dice: GameDice;
  index: number;
}

interface FormValues {
  name: string;
  color?: string;
  textColor?: string;
  fields: string;
}

export const DiceDetailEditView = (props: CardDetailEditViewProps) => {
  const { dice } = props;

  const { t } = useTranslation();

  const { game } = useGame();

  const form = useForm<FormValues>({
    defaultValues: {
      ...dice,
      fields: dice.fields?.join(","),
    },
  });
  const { register, setValue } = form;

  const update = (updater: (t: GameDice) => void) => {
    const dices = game.root.settings?.dices ?? [];
    const dbItem = dices.find((x) => x.key === dice.key);
    if (!dbItem) return;
    updater(dbItem);
    cuni.update(SETTINGS_ADMIN_KEY, "dices", dices);
  };
  return (
    <FormProvider {...form}>
      <div className="column">
        <div className="paper-outline">
          <Popover
            renderButton={(onClick) => (
              <Tooltip title={t("TOKEN.PREVIEW")}>
                <IconButton onClick={onClick}>
                  <PreviewIcon />
                </IconButton>
              </Tooltip>
            )}
          >
            <DiceView item={dice} fixed />
          </Popover>
        </div>

        <TextField
          label={t("COMMON.NAME")}
          {...register("name")}
          onBlur={(e) => {
            update((t) => (t.name = e.currentTarget.value));
          }}
        />
        <Box display="flex" gap="1rem">
          <Box
            display="flex"
            flex="1"
            alignItems="center"
            padding="6px 14px"
            gap="0.25rem"
            sx={{
              border: "1px solid rgba(255, 255, 255, 0.2)",
              borderRadius: "4px",
            }}
          >
            <Typography
              sx={{
                color: "rgba(255, 255, 255, 0.7)",
              }}
              variant="body1"
            >
              {t("GAME.COLOR")}
            </Typography>
            <ColorPickerField
              onChange={(color) => {
                update((t) => (t.color = color));
              }}
            />
          </Box>

          <Box
            display="flex"
            flex="1"
            alignItems="center"
            padding="6px 14px"
            gap="0.25rem"
            sx={{
              border: "1px solid rgba(255, 255, 255, 0.2)",
              borderRadius: "4px",
            }}
          >
            <Typography
              sx={{
                color: "rgba(255, 255, 255, 0.7)",
              }}
              variant="body1"
            >
              {t("GAME.TEXT_COLOR")}
            </Typography>

            <ColorPicker
              selected={form.watch("textColor")}
              onColorChange={(color) => {
                setValue("textColor", color);
                update((t) => (t.textColor = color));
              }}
            />
          </Box>
        </Box>
        <TextField
          label={t("TOKEN.SIDE")}
          {...register("fields")}
          onBlur={(e) => {
            update((t) => (t.fields = e.currentTarget.value.split(",")));
          }}
        />
      </div>
    </FormProvider>
  );
};
