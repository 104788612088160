import { useMemo } from "react";
import { useGame } from "../../character/hooks/useGame";
import {
  makeSections,
  makeInternal,
  Character,
  Game,
} from "../../../model/Game";

export const useGameDb = () => {
  const { game } = useGame();
  return useMemo(() => getGameDbList(game), [game]);
};

export const getGameDbList = (game: Game) => {
  let list: Character[] = [];
  if (game.characters) {
    const characters = Object.entries(game.characters).map((x) => x[1]);
    list = [...list, ...characters];
  }

  if (game.root.sections) list.push(makeSections(game.root.sections));
  if (game.root.internal) list.push(makeInternal(game.root.internal));
  return { list };
};

export const useGameDbById = (key: number) => {
  const { list: dbList } = useGameDb();
  return dbList.find((x) => x.key === key);
};
