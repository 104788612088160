import { useMemo } from "react";
import { User } from "../../model/User";
import { useAuth } from "./useAuth";

export const useUser = (): User | null => {
  const { data: auth } = useAuth();
  if (!auth) throw new Error("You need to be authorized.");

  const authJson = JSON.stringify(auth);
  return useMemo(() => {
    return {
      id: auth.userId,
      ...auth,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authJson]);
};
