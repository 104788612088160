import { useState } from "react";
import { GameDice } from "../../model/Game";
import "./DiceView.css";
import { useMapDrag } from "./use-map-drag";
import { cuni } from "../../shared/services/cuni/cuni";

interface DiceViewProps {
  item: GameDice;
  onClick?: () => void;
  fixed?: boolean;
  disabled?: boolean;
  sendRoll?: boolean;
}

export const DiceView = (props: DiceViewProps) => {
  const { fixed, disabled, item, onClick, sendRoll } = props;
  const [value, setValue] = useState<string>(item.fields?.[0] || "6");

  const number = parseInt(value);

  const handlers = useMapDrag({
    draggableDb: "dices",
    key: item.key,
    position: item.position,
    fixed,
    classNameSelector: "dice",
  });

  const submitDice = () => {
    if (onClick) {
      onClick();
      return;
    }
    const randomNumber = Math.floor(Math.random() * (item.fields?.length || 6));

    let newValue = "";
    if (item.fields) {
      newValue = item.fields[randomNumber];
    } else {
      newValue = `${randomNumber + 1}`;
    }

    setValue(newValue);
    if (sendRoll) cuni.likeRoll(item.name || "CUSTOM", newValue);
  };

  return (
    <div
      className={"dice disable-map" + (disabled ? "dice-disabled" : "")}
      onClick={submitDice}
      {...handlers}
      style={{
        background: item.color || "white",
        color: item.textColor || "black",
        ...handlers.style,
      }}
    >
      <div className="disable-map" style={{ position: "absolute" }}>
        {item.name}
      </div>
      {!number || number > 9 ? (
        <div className="disable-map dice-snail">{value}</div>
      ) : (
        <div className={`disable-map dot-container dot-${value}`}>
          {Array.from({ length: number }).map((_, index) => (
            <div
              key={index}
              className="dot disable-map"
              style={{
                backgroundColor: item.textColor || "black",
              }}
            ></div>
          ))}
        </div>
      )}
    </div>
  );
};
