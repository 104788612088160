import { observer } from "mobx-react";
import CharacterView from "../features/character/CharacterView";
import { GameProvider, useGame } from "../features/character/hooks/useGame";
import Chat from "../features/chat/Chat";
import { rootStore } from "../store/RootStore";
import { GameTabSectionEditView } from "../features/schema/GameTabSectionEditView";
import { MobileToolbar } from "./mobile-navigation/MobileToolbar";
import { useMediaQuery } from "@mui/material";
import { Conditional } from "../shared/components/logic/Conditional";
import { useState } from "react";
import { useBackgroundImage } from "../shared/hooks/useBackgoundImage";
import { GmToolbox } from "../features/gm-toolbox/GmToolbox";
import { GameLayout } from "../model/Game";
import { MapProvider } from "../features/map-hex/components/MapProvider";
import { MapHexDisplay } from "../features/map-hex/MapHex";
import { DEFAULT_LAYOUT } from "../features/game-general/components/LayoutView";

const GamePageInternal = () => {
  const isMobile = useMediaQuery("(max-width: 1024px)");
  const [mobileTab, setMobileTab] = useState("chat");

  useBackgroundImage();

  const { game } = useGame();
  const tabs = ["character", "chat"];
  if (!game.root?.layout?.right.hidden) {
    tabs.push("root");
  }

  const renderLayout = (panelKey: keyof GameLayout) => {
    const layout = game.root?.layout ?? DEFAULT_LAYOUT;
    const panel = layout?.[panelKey];
    const type = panel?.panels?.[0];
    switch (type) {
      case "character":
        return (
          <Conditional condition={!isMobile || mobileTab === "character"}>
            <div className="app__block">
              <CharacterView style={{ paddingRight: "1rem" }} />
            </div>
          </Conditional>
        );

      case "chat":
        return (
          <Conditional condition={!isMobile || mobileTab === "chat"}>
            <Chat />
          </Conditional>
        );
      case "table":
        return (
          <MapProvider>
            <div className="mapHex">
              <MapHexDisplay />
            </div>
          </MapProvider>
        );
      case "story":
        return (
          <Conditional condition={!isMobile || mobileTab === "root"}>
            <GameTabSectionEditView
              editEnabled={false}
              style={isMobile ? undefined : { paddingLeft: "1rem" }}
            />
          </Conditional>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <div className="App">
        <Conditional condition={!game.root?.layout?.left?.hidden}>
          {renderLayout("left")}
        </Conditional>
        <Conditional condition={!game.root?.layout?.middle?.hidden}>
          {renderLayout("middle")}
        </Conditional>
        <Conditional condition={!game.root?.layout?.right?.hidden}>
          {renderLayout("right")}
        </Conditional>
        <Conditional condition={isMobile}>
          <MobileToolbar current={mobileTab} tabs={tabs} set={setMobileTab} />
        </Conditional>
      </div>
      <GmToolbox />
    </>
  );
};

// TODO: validate query parameter
export const GamePage = observer(() => (
  <GameProvider game={rootStore.game}>
    <GamePageInternal />
  </GameProvider>
));
