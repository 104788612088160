import AWS from "aws-sdk";
import { uuid } from "../utils/uuid";
import { useCallback, useMemo } from "react";
import { useResources } from "./use-resources";
import { Resource } from "../../model/Resource";
import { apiClient } from "../services/apiClient";
import { config } from "../../config";

const spacesEndpoint = new AWS.Endpoint("fra1.digitaloceanspaces.com"); // Replace with your region's endpoint
const s3 = new AWS.S3({
  endpoint: spacesEndpoint,
  accessKeyId: "DO00LJWYL9BNQ6KZMEPR", // Replace with your access key
  secretAccessKey: "MtTY4qXqbmnPkps7yzxN9dGd65szShKSqm1pSgmPYZA", // Replace with your secret key
});

export const useAws = () => {
  const { data, refetch } = useResources();

  const upload = useCallback(
    async (
      files: File[],
      onUpload: (key: string, location: string) => void,
    ) => {
      const filteredFiles = files.filter((file) => {
        if (file.size > 1024 * 1024 * 10) {
          alert(`File ${file.name} is too large. Max size is 10MB.`);
          return false;
        }
        return true;
      });

      const sumFile = filteredFiles.reduce((p, c) => p + c.size, 0);
      const alreadySavedFiles = (data ?? []).reduce((p, c) => p + c.size, 0);

      if (sumFile + alreadySavedFiles > 1024 * 1024 * 10) {
        alert(`Maximum size of your plan is 100MB.`);
        return;
      }

      if (filteredFiles.length === 0) return;

      filteredFiles.forEach((file) => {
        const nameParts = file.name.split("/");
        const fileName = nameParts.pop();
        if (!fileName) throw new Error("File has no name");
        const ext = fileName.split(".")[1];
        const key = [...nameParts, `${uuid()}.${ext}`].join("/");

        const params = {
          Bucket: "cuni", // Replace with your Space name
          Key: key,
          Body: file,
          ACL: "public-read", // or 'private'
        };

        s3.upload(
          params,
          async (err: any, data: AWS.S3.ManagedUpload.SendData) => {
            if (err) {
              console.error(`Error uploading file ${file.name}: `, err);
              return;
            }
            console.log(`File uploaded successfully: ${data.Location}`);
            await apiClient.post<Resource>(`${config.baseUrl}/api/resource`, {
              key: data.Key,
              url: data.Location,
              size: file.size,
            });
            onUpload(data.Key, data.Location);
          },
        );
      });
    },
    [data],
  );

  const remove = useCallback(
    async (id: number | string | string[]) => {
      const removeOne = async (resource?: Resource) => {
        if (!resource) {
          console.error("There is no resource.");
          return;
        }
        const params: AWS.S3.DeleteObjectRequest = {
          Bucket: "cuni", // Replace with your Space name
          Key: resource.key,
        };

        await apiClient.delete<Resource>(
          `${config.baseUrl}/api/resource/${resource.id}`,
        );
        s3.deleteObject(params, (err, data) => {
          console.log(err, data);
        });

        refetch();
      };

      if (Array.isArray(id)) {
        const tasks = id?.map((y) => {
          const resource = data?.find((x) => x.key === y);
          return removeOne(resource);
        });
        await Promise.all(tasks);
      } else if (typeof id === "string") {
        const resource = data?.find((x) => x.key === id);
        await removeOne(resource);
      } else {
        const resource = data?.find((x) => x.id === id);
        await removeOne(resource);
      }
    },
    [data, refetch],
  );

  return useMemo(() => {
    return {
      upload,
      remove,
    };
  }, [upload]);
};
