import { useTranslation } from "react-i18next";
import { CharacterCardPanel } from "../../../../../model/Game";
import { AreYouSureModal } from "../../../../../shared/components/modal/AreYouSureModal";
import { cuni } from "../../../../../shared/services/cuni/cuni";
import { cardUtils } from "../../../../../shared/utils/cardUtils";
import { uuid } from "../../../../../shared/utils/uuid";
import { GameCardView } from "../../../../cards/GameCardView";
import { MapHexTableDrop } from "../../../../map-hex/MapHexDrop";
import { updatePanel } from "../../../../tokens/hooks/useGameDbPanels";
import { useGame } from "../../../hooks/useGame";
import "./CardPanelView.css";
import { useDrop } from "react-dnd";
import { MapCardPlace } from "../../../../../model/Map";
import { useState } from "react";

interface CardPanelViewProps {
  panel: CharacterCardPanel;
  path: string;
}

export const CardPanelView = (props: CardPanelViewProps) => {
  const { panel } = props;
  const { game } = useGame();
  const { t } = useTranslation();

  const [multipleOpen, setMultipleOpen] = useState<MapCardPlace | null>(null);

  const handleDrop = (deckPanel: MapCardPlace) => {
    const card = cardUtils.pickFromDeck(game, deckPanel.key);
    if (!card) return;
    updatePanel(panel.key, (state: CharacterCardPanel) => {
      state.cards.push({
        key: uuid(),
        deckFlyweightKey: deckPanel.deckFlyweightKey ?? deckPanel.key,
        cardKey: card!,
      });
      return state;
    });
    cuni.updateGame("maps", game.root?.maps);
  };

  const [{ isOver }, drop] = useDrop({
    accept: "TOKEN-CARD",
    drop: (item) => {
      const { deck: deckPanel, multiple } = item as MapHexTableDrop;
      if (multiple) {
        setMultipleOpen(deckPanel);
      } else handleDrop(deckPanel);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  return (
    <div
      ref={drop}
      style={{
        borderColor: isOver ? "gray" : "white",
      }}
      className="card-panel-view"
    >
      {panel.cards.map((card) => (
        <GameCardView card={card} panel={panel} />
      ))}
      <AreYouSureModal
        title={t("GAME.ADD_ROW")}
        content={t("GAME.ADD_ROW_DESC")}
        confirmAction={(fields) => {
          if (fields && parseInt(fields.value) && multipleOpen?.key) {
            const value = parseInt(fields.value);
            const cards: string[] = [];
            for (let i = 0; i < value; i++) {
              const card = cardUtils.pickFromDeck(game, multipleOpen.key);
              // TODO: if no card we should shuffle
              if (card) cards.push(card);
            }
            updatePanel(panel.key, (state: CharacterCardPanel) => {
              cards.forEach((x) => {
                state.cards.push({
                  key: uuid(),
                  deckFlyweightKey: multipleOpen.key,
                  cardKey: x,
                });
              });

              return state;
            });
            cuni.updateGame("maps", game.root?.maps);
          }
        }}
        open={!!multipleOpen}
        handleClose={() => setMultipleOpen(null)}
        fields={[
          {
            name: "value",
          },
        ]}
      />
    </div>
  );
};
