import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { QuickEditModal } from "../QuickEditModal";
import { Character } from "../../../model/Game";
import { IconButton } from "@mui/material";
import CasinoIcon from "@mui/icons-material/Casino";
import { canRoll } from "../../../shared/services/cuni/utils";
import { cuni } from "../../../shared/services/cuni/cuni";
import EditIcon from "@mui/icons-material/Edit";
import { cutLastNParts, getPropertyByPath } from "../flattenDict";

export type QuickEditType =
  | "number"
  | "string"
  | "INTERACTION_OPERAND"
  | "INTERACTION_CONDITION"
  | "HINTS";
export interface QuickEditState {
  path: string;
  value: string | number;
  type?: QuickEditType;
  label?: string;
}
interface QuickEditModalContextState {
  edit: (context: QuickEditState) => void;
  character: Character;
}

const QuickEditModalContext = createContext<QuickEditModalContextState | null>(
  null,
);

interface QuickEditModalProviderProps {
  character: Character;
}

export const QuickEditModalProvider = ({
  children,
  character,
}: PropsWithChildren<QuickEditModalProviderProps>) => {
  const [context, setContext] = useState<QuickEditState | null>(null);

  return (
    <QuickEditModalContext.Provider value={{ edit: setContext, character }}>
      <QuickEditModal
        open={Boolean(context)}
        character={character}
        {...context}
        handleClose={() => setContext(null)}
      />
      {children}
    </QuickEditModalContext.Provider>
  );
};
export const useQuickEdit = () => {
  const context = useContext(QuickEditModalContext);
  if (!context) throw new Error("There is no QuickEditModalProvider");

  const { edit } = context;
  const getProps = useCallback(
    (input: QuickEditState) => {
      const actionComponent = canRoll(input.value) ? (
        <div className="genericCharacter__EditMode-toolbox paper__menu">
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();

              const namePath = cutLastNParts(input.path, 1);
              const hasName = getPropertyByPath(
                context.character,
                namePath + ".__contextName",
              );
              const name = getPropertyByPath(
                context.character,
                namePath + ".title",
              );
              cuni.userRoll(input.value.toString(), hasName ? name : undefined);
            }}
          >
            <CasinoIcon />
          </IconButton>
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              edit(input);
            }}
          >
            <EditIcon />
          </IconButton>
        </div>
      ) : null;
      return {
        actionComponent,
        className: "genericCharacter__EditMode",
        onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          e.stopPropagation();
          edit(input);
        },
      };
    },
    [edit, context.character],
  );

  return { ...context, getProps };
};
