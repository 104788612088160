import { Box, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import { GameCard, GameDeck, SETTINGS_ADMIN_KEY } from "../../model/Game";
import { useTranslation } from "react-i18next";
import { useGame } from "../character/hooks/useGame";
import PreviewIcon from "@mui/icons-material/Preview";
import { Popover } from "../../shared/components/ui/popover/Popover";
import { cuni } from "../../shared/services/cuni/cuni";
import { Controller, useForm } from "react-hook-form";
import { CardView } from "../cards/CardView";
import SelectField, {
  SelectOption,
} from "../../shared/components/logic/SelectField";
import { ColorPicker } from "../../shared/components/ui/color-picker/ColorPicker";
import { Divider } from "../../shared/components/ui/Divider";

interface CardDetailEditViewProps {
  deck: GameDeck;
  index: number;
}

interface Form {
  name: string;
  reverseAvatarUrl: string;
  group: string;
  textColor?: string;
  color?: string;
  uiType?: GameCard["uiType"];
  cardTextColor?: string;
  cardColor?: string;
}

export const DeckDetailEditView = (props: CardDetailEditViewProps) => {
  const { deck } = props;

  const { t } = useTranslation();

  const { game } = useGame();

  const { register, control, setValue, watch } = useForm<Form>({
    defaultValues: deck,
  });

  const update = (updater: (t: GameDeck) => void) => {
    const decks = game.root.settings?.decks ?? [];
    const dbItem = decks.find((x) => x.key === deck.key);
    if (!dbItem) return;
    updater(dbItem);
    cuni.update(SETTINGS_ADMIN_KEY, "decks", decks);
  };
  return (
    <div className="column">
      <div className="paper-outline">
        <Popover
          renderButton={(onClick) => (
            <Tooltip title={t("TOKEN.PREVIEW")}>
              <IconButton onClick={onClick}>
                <PreviewIcon />
              </IconButton>
            </Tooltip>
          )}
        >
          <CardView {...deck} avatarUrl={deck.reverseAvatarUrl} />
        </Popover>
      </div>

      <TextField
        label={t("COMMON.NAME")}
        {...register("name")}
        onBlur={(e) => {
          update((t) => (t.name = e.currentTarget.value));
        }}
      />
      <TextField
        label={t("TOKEN.AVATAR")}
        {...register("reverseAvatarUrl")}
        onBlur={(e) => {
          update((t) => (t.reverseAvatarUrl = e.currentTarget.value));
        }}
      />
      <Divider title={t("CARD.BACK")} />
      <Box display="flex" gap="1rem">
        <Box
          display="flex"
          flex="1"
          alignItems="center"
          padding="6px 14px"
          gap="0.25rem"
          sx={{
            border: "1px solid rgba(255, 255, 255, 0.2)",
            borderRadius: "4px",
          }}
        >
          <Typography
            sx={{
              color: "rgba(255, 255, 255, 0.7)",
            }}
            variant="body1"
          >
            {t("GAME.COLOR")}
          </Typography>
          <ColorPicker
            selected={watch("cardColor")}
            onColorChange={(color) => {
              setValue("cardColor", color);
              update((t) => (t.cardColor = color));
            }}
          />
        </Box>

        <Box
          display="flex"
          flex="1"
          alignItems="center"
          padding="6px 14px"
          gap="0.25rem"
          sx={{
            border: "1px solid rgba(255, 255, 255, 0.2)",
            borderRadius: "4px",
          }}
        >
          <Typography
            sx={{
              color: "rgba(255, 255, 255, 0.7)",
            }}
            variant="body1"
          >
            {t("GAME.TEXT_COLOR")}
          </Typography>

          <ColorPicker
            selected={watch("cardTextColor")}
            onColorChange={(color) => {
              setValue("cardTextColor", color);
              update((t) => (t.cardTextColor = color));
            }}
          />
        </Box>
      </Box>
      <Divider title={t("CARD.FRONT")} />
      <Box display="flex" gap="1rem">
        <Box
          display="flex"
          flex="1"
          alignItems="center"
          padding="6px 14px"
          gap="0.25rem"
          sx={{
            border: "1px solid rgba(255, 255, 255, 0.2)",
            borderRadius: "4px",
          }}
        >
          <Typography
            sx={{
              color: "rgba(255, 255, 255, 0.7)",
            }}
            variant="body1"
          >
            {t("GAME.COLOR")}
          </Typography>
          <ColorPicker
            selected={watch("color")}
            onColorChange={(color) => {
              setValue("color", color);
              update((t) => (t.color = color));
            }}
          />
        </Box>

        <Box
          display="flex"
          flex="1"
          alignItems="center"
          padding="6px 14px"
          gap="0.25rem"
          sx={{
            border: "1px solid rgba(255, 255, 255, 0.2)",
            borderRadius: "4px",
          }}
        >
          <Typography
            sx={{
              color: "rgba(255, 255, 255, 0.7)",
            }}
            variant="body1"
          >
            {t("GAME.TEXT_COLOR")}
          </Typography>

          <ColorPicker
            selected={watch("textColor")}
            onColorChange={(color) => {
              setValue("textColor", color);
              update((t) => (t.textColor = color));
            }}
          />
        </Box>
      </Box>
      <Controller
        name="uiType"
        control={control}
        render={({ field }) => {
          return (
            <SelectField<SelectOption>
              label={t("CARD_TYPES.TYPE")}
              value={{
                key: field.value,
                name: t(`CARD_TYPES.${field.value}`),
              }}
              onChange={(e: SelectOption) => {
                const uiType =
                  typeof e === "object" && "key" in e
                    ? (e.key as GameCard["uiType"])
                    : "CARD";
                setValue("uiType", uiType);
                update((t) => (t.uiType = uiType));
              }}
              options={["FREE", "SQUARE", "ROUND", "CARD"].map((x) => ({
                key: x,
                name: t(`CARD_TYPES.${x}`),
              }))}
            />
          );
        }}
      />
    </div>
  );
};
