import { useGame } from "../../character/hooks/useGame";

export const useCards = () => {
  const { game } = useGame();
  return game.root.settings?.cards ?? [];
};


export const useDecks = () => {
  const { game } = useGame();
  return game.root.settings?.decks ?? [];
}